import {isNumber} from 'lodash';

export function getWindowInnerWidth() {
    return typeof window !== 'undefined' ? window.innerWidth : 0;
}

export function onShopperApprovedContextMenu() {
    const d = new Date();
    alert(`Copying Prohibited by Law - This image and all included logos are copyrighted by Shopper Approved ${d.getFullYear()}.`);
    return false;
}

export function getRootUrl() {
    return typeof window !== 'undefined' ? window.location.origin : '';
}

export function getTableCellHeaders(subHeaderIndex, rowIndex, cellIndex, colSpan = 1) {
    let headers = isNumber(subHeaderIndex) ? `sub-header-${subHeaderIndex} ` : '';
    headers += `row-header-${rowIndex} `;

    if (colSpan) {
        headers += new Array(colSpan)
            .fill(0)
            .map(() => `col-header-${cellIndex}`)
            .join(' ');
    }

    return headers;
}

export const scollToTop = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }
};
