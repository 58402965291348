import {
    REPLACE_CATEGORY_PG_LINKS,
    REPLACE_CATEGORY_BLOG_PG_RELATED_DATA,
    REPLACE_CATEGORY_EMAILS,
    REPLACE_CHAT_PG_LINKS,
    REPLACE_CHAT_EMAILS,
    REPLACE_CONFIG_PG_LINKS,
    REPLACE_CONFIG_EMAILS,
    REPLACE_LAST_CART_PG_LINKS,
    REPLACE_LAST_CART_EMAILS,
    REPLACE_NAME_CONFIG_PG_LINKS,
    REPLACE_NAME_CONFIG_EMAILS,
    REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS,
    REPLACE_PREFILL_IMPRINT_OPTION_EMAILS,
    REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA,
    REPLACE_SEARCH_PG_LINKS,
    REPLACE_SEARCH_EMAILS,
    REPLACE_PAGE_PG_RELATED_DATA
} from '../actionTypes';

export function hideEmailsAction() {
    return dispatch => {
        dispatch({type: REPLACE_CATEGORY_EMAILS});
        dispatch({type: REPLACE_CONFIG_EMAILS});
        dispatch({type: REPLACE_NAME_CONFIG_EMAILS});
        dispatch({type: REPLACE_CHAT_EMAILS});
        dispatch({type: REPLACE_PREFILL_IMPRINT_OPTION_EMAILS});
        dispatch({type: REPLACE_LAST_CART_EMAILS});
        dispatch({type: REPLACE_SEARCH_EMAILS});
    };
}

export function replacePGRelatedData() {
    return (dispatch, getState) => {
        const {wlConfig} = getState().config;

        // Hide blogs and links to PrintGlobe
        dispatch({type: REPLACE_CATEGORY_PG_LINKS});
        dispatch({type: REPLACE_CONFIG_PG_LINKS});
        dispatch({type: REPLACE_NAME_CONFIG_PG_LINKS});
        dispatch({type: REPLACE_CHAT_PG_LINKS});
        dispatch({type: REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS});
        dispatch({type: REPLACE_LAST_CART_PG_LINKS});
        dispatch({type: REPLACE_SEARCH_PG_LINKS});

        // Replace pg related data of categories and products
        dispatch({type: REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA, wlConfig});
        dispatch({type: REPLACE_PAGE_PG_RELATED_DATA, wlConfig});
        dispatch({type: REPLACE_CATEGORY_BLOG_PG_RELATED_DATA, wlConfig});
    };
}
