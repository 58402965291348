import {isEmpty, union} from 'lodash';
import {defaultBusinessNameOfficial, defaultScriptType, usdCurrency} from '../constants/common';
import {cookieScriptTypes} from '../constants/cookies';
import {calculateSubtotal, calculateTotal, calculateItemTotal, calculateDefaultProductSetupFee} from './calculate';
import {sendIdentifyEvent, sendTrackEvent} from './klaviyo';
import {getCategories, getFullProductUrl, getGACategories} from '../helpers/product';
import {calculateItemsTotal, convertCartItemToKlaviyoCartItem} from '../helpers/cart';
import {restoreHiddenEmails, roundTo2DecimalPlaces} from '../helpers/common';
import {addAnswerBaseConversionTrackerScript} from '../helpers/shopperApproved';

export function sendCheckoutEvent(index) {
    if (index === 0 && typeof fbq !== 'undefined') {
        fbq('track', 'InitiateCheckout');
    }
    if (index > 0) {
        if (typeof window !== 'undefined' && window.uetq) {
            window.uetq.push({ec: 'checkout', ea: 'checkout-step', el: index});
        }
    }
}

function sendKlaviyoCheckoutEvent(nameEvent, cart, urlRoot, totalValue = 0) {
    const cartValue = totalValue || cart.reduce(calculateItemsTotal, 0);
    const items = cart.map(item => convertCartItemToKlaviyoCartItem(item, urlRoot));

    const categories = items.reduce((prev, current) => [...prev, ...current.ProductCategories], []);

    const cartData = {
        $value: roundTo2DecimalPlaces(cartValue),
        ItemNames: items && items.length ? items.map(item => item.ProductName) : [],
        CheckoutURL: `${urlRoot}/checkout`,
        Categories: union(categories),
        Items: items && items.length ? items : []
    };
    sendTrackEvent(nameEvent, cartData);
}

export function sendStartedCheckoutEvent(cart, urlRoot) {
    sendKlaviyoCheckoutEvent('Started Checkout', cart, urlRoot);
}

export function sendCheckoutCompleteEvent(
    cart,
    id,
    totalPrice,
    tax,
    urlRoot,
    discount,
    email,
    isSampleCheckout = false,
    enableCookieSettings = false,
    marketing = false
) {
    const checkoutEventAction = isSampleCheckout ? 'checkout-complete-sample' : 'checkout-complete';
    if (typeof gtag !== 'undefined') {
        const items = cart.map((item, itemIndex) => ({
            item_id: item.product.public_sku,
            item_name: item.product.name,
            affiliation: defaultBusinessNameOfficial,
            currency: usdCurrency,
            index: itemIndex + 1,
            item_brand: defaultBusinessNameOfficial,
            price: item.subtotal / item.quantity,
            quantity: item.quantity,
            ...getGACategories(item.product.parents)
        }));

        gtag('event', 'purchase', {
            currency: usdCurrency,
            transaction_id: id,
            value: totalPrice,
            affiliation: defaultBusinessNameOfficial,
            tax: tax,
            items
        });
    }
    if (typeof window !== 'undefined' && window.uetq) {
        window.uetq.push({ec: 'checkout', ea: checkoutEventAction, el: id, ev: totalPrice});
    }
    if (typeof window !== 'undefined') {
        window.AddShoppersConversion = {order_id: id, value: totalPrice};
        const js = document.createElement('script');
        js.type = enableCookieSettings ? cookieScriptTypes.marketing : defaultScriptType;
        js.async = true;
        js.id = 'AddShoppers';
        js.src =
            ('https:' === document.location.protocol ? 'https://shop.pe/widget/' : 'http://cdn.shop.pe/widget/') + 'widget_async.js#5aa04973bbddbdb17f2fb753';
        document.getElementsByTagName('head')[0].appendChild(js);
    }

    // PRINTGLOBESUP-1570
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'agiLiveChatConversion',
            agiLiveChatTransactionID: id,
            agiLiveChatOrderValue: totalPrice
        });
    }

    sendKlaviyoCheckoutEvent('Completed Checkout', cart, urlRoot, Number(totalPrice));

    if (typeof fbq !== 'undefined') {
        cart.forEach(ci => {
            let surfaceColorId = ci.surface_color_id;
            if (!surfaceColorId) {
                const product = ci.product;
                surfaceColorId =
                    product.surface_palette && product.surface_palette.surface_colors && product.surface_palette.surface_colors.length
                        ? product.surface_palette.surface_colors[0].id
                        : 0;
            }
            fbq('track', 'Purchase', {
                value: calculateItemTotal(ci, discount),
                currency: usdCurrency,
                content_ids: `N${ci.product.id}C${surfaceColorId}`,
                content_type: getProductType(ci.product)
            });
        });
    }

    const skuStr = cart.map(item => item.product.public_sku).join(',');
    const idStr = cart.map(item => item.product.id).join(',');
    const priceStr = cart.map(item => item.subtotal).join(',');
    addAnswerBaseConversionTrackerScript(enableCookieSettings, marketing, totalPrice, email, skuStr, idStr, priceStr);
}

export function sendSampleRequest() {
    // #4754 asked us to remove these events
    // if (typeof ga !== 'undefined') {
    // 	ga('send', 'event', 'sample');
    // }
    // if (typeof window !== 'undefined' && window.uetq) {
    // 	window.uetq.push({ ec: 'sample' });
    // }
}

export function sendReorderRequest() {
    if (typeof window !== 'undefined' && window.uetq) {
        window.uetq.push({ec: 'reorder'});
    }
}

export function sendRegistration() {
    if (typeof fbq !== 'undefined') {
        fbq('track', 'CompleteRegistration');
    }
    if (typeof window !== 'undefined' && window.uetq) {
        window.uetq.push({ec: 'registration'});
    }
}

export function sendRemarketingEvent(pagetype) {
    if (window && window.google_trackConversion) {
        window.google_trackConversion({
            google_conversion_id: 1072727840,
            google_custom_params: {
                ecomm_pagetype: pagetype
            },
            google_remarketing_only: true
        });
    }
}

function getProductType(product) {
    let productType = '';
    if (product.parents.length) {
        product.parents[0].forEach(p => {
            if (productType === '') {
                productType = p.name;
            } else {
                productType = `${productType}|${p.name}`;
            }
        });
    }
    return productType.replace(/,/g, '');
}

export function sendProductRemarketingEvent(urlRoot, product) {
    const subtotal = Number(calculateSubtotal(product.quantities[0], product.options[0], product));
    const totalvalue = subtotal + calculateDefaultProductSetupFee(product);
    if (window && window.google_trackConversion) {
        window.google_trackConversion({
            google_conversion_id: 1072727840,
            google_custom_params: {
                ecomm_prodid: `N${product.id}C${
                    product.surface_palette && product.surface_palette.surface_colors && product.surface_palette.surface_colors.length
                        ? product.surface_palette.surface_colors[0].id
                        : 0
                }`,
                ecomm_pagetype: 'product',
                ecomm_totalvalue: totalvalue
            },
            google_remarketing_only: true
        });
    }
    sendTrackEvent('Viewed Product', {
        ProductID: product.id,
        SKU: product.public_sku,
        ProductName: product.name,
        Categories: getCategories(product),
        ImageURL: `${urlRoot}${product.primary_image.url_path}`,
        URL: getFullProductUrl(urlRoot, product),
        Price: roundTo2DecimalPlaces(subtotal)
    });

    if (typeof fbq !== 'undefined') {
        fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: `N${product.id}C${
                product.surface_palette && product.surface_palette.surface_colors && product.surface_palette.surface_colors.length
                    ? product.surface_palette.surface_colors[0].id
                    : 0
            }`,
            content_name: `${product.min_order} Custom ${product.name ? product.name : ''}`,
            content_category: getProductType(product),
            value: `${calculateTotal(product.quantities[0], product.options[0], product, [], [])}`,
            currency: usdCurrency
        });
    }
}

export function sendCartRemarketingEvent(totalValue) {
    if (window && window.google_trackConversion) {
        window.google_trackConversion({
            google_conversion_id: 1072727840,
            google_custom_params: {
                ecomm_pagetype: 'cart',
                ecomm_totalvalue: totalValue
            },
            google_remarketing_only: true
        });
    }
}

export function removeCartItem(id) {
    sendTrackEvent('Remove Cart Item', {product_id: `${id}`});
}

export function addToCart(cart, cartItem, product, price, urlRoot) {
    if (typeof fbq !== 'undefined') {
        let surfaceColorId = cartItem.surface_color_id;
        if (!surfaceColorId) {
            surfaceColorId =
                product.surface_palette && product.surface_palette.surface_colors && product.surface_palette.surface_colors.length
                    ? product.surface_palette.surface_colors[0].id
                    : 0;
        }
        fbq('track', 'AddToCart', {
            value: price,
            currency: usdCurrency,
            content_ids: `N${product.id}C${surfaceColorId}`,
            content_type: getProductType(product)
        });
    }
    if (typeof gtag !== 'undefined') {
        gtag('event', 'add_to_cart', {
            currency: usdCurrency,
            value: price,
            items: [
                {
                    item_id: product.public_sku,
                    item_name: product.name,
                    affiliation: defaultBusinessNameOfficial,
                    index: cart.length + 1,
                    item_brand: defaultBusinessNameOfficial,
                    price: price / cartItem.quantity,
                    quantity: cartItem.quantity,
                    ...getGACategories(product.parents)
                }
            ]
        });
    }
    addedToCart(urlRoot, cart, {...cartItem, product, subtotal: price});
}

function addedToCart(urlRoot, cart, addedItem) {
    if (typeof _learnq !== 'undefined' && addedItem) {
        const copyCart = isEmpty(cart) ? [] : [...cart];
        const cartValue = copyCart.reduce(calculateItemsTotal, 0);
        const cartItems = copyCart.map(item => convertCartItemToKlaviyoCartItem(item, urlRoot));
        const addedItemKlaviyo = convertCartItemToKlaviyoCartItem(addedItem, urlRoot);
        const items = cartItems && cartItems.length ? [...cartItems, addedItemKlaviyo] : [addedItemKlaviyo];

        const cartData = {
            $value: roundTo2DecimalPlaces(cartValue + Number(addedItem.subtotal)),
            AddedItemProductName: addedItem.product.name,
            AddedItemProductID: addedItem.product.id.toString(),
            AddedItemSKU: addedItem.product.public_sku,
            AddedItemCategories: getCategories(addedItem.product),
            AddedItemImageURL: `${urlRoot}${addedItem.product.primary_image.url_path}`,
            AddedItemURL: getFullProductUrl(urlRoot, addedItem.product),
            AddedItemPrice: roundTo2DecimalPlaces(addedItem.subtotal / addedItem.quantity),
            AddedItemQuantity: addedItem.quantity,
            ItemNames: items && items.length ? items.map(item => item.ProductName) : [],
            CheckoutURL: `${urlRoot}/checkout`,
            Items: items
        };
        sendTrackEvent('Added to Cart', cartData);
    }
}

export function setContactInfo(contactInfo) {
    if (!contactInfo) {
        return;
    }
    sendIdentifyEvent({
        $email: restoreHiddenEmails(contactInfo.email),
        $first_name: contactInfo.firstName,
        $last_name: contactInfo.lastName
    });
}
