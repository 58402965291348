import {adminPhoneRegex, printGlobeMatchingRegex} from '../constants/regex';

export const replacePrintglobeRelatedData = (value, wlConfig) =>
    wlConfig.isPartner
        ? JSON.parse(
              JSON.stringify(value)
                  .replace(adminPhoneRegex, () => wlConfig.phone)
                  .replace(printGlobeMatchingRegex, () => wlConfig.business_name)
          )
        : value;
