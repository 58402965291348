/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import * as analytics from '../lib/analytics';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';
import {getAbleToCheckoutItems, getNotAbleToCheckoutItems} from '../helpers/cart';

const defaultState = {
    addresses: [],
    cart: [],
    contactInfo: {},
    loggedIn: false,
    adminLoggedIn: false,
    modifyCartItem: {},
    orders: [],
    paymentMethods: [],
    recentItems: [],
    saved: [],
    last_cart: []
};

function DELETE_ADDRESS(state, action) {
    const {id} = action;
    const newAddresses = state.addresses.filter(address => address.id !== id);
    return {...state, addresses: newAddresses};
}

function DELETE_CART_ITEM(state, action) {
    const {loggedIn, contactInfo} = state;
    const {id} = action;
    let deletedItem = null;
    const newCart = state.cart.filter(element => {
        deletedItem = element;
        return element.id !== id;
    });

    if (loggedIn && contactInfo) {
        analytics.removeCartItem(deletedItem.id);
    }

    return {...state, cart: newCart};
}

function DELETE_PAYMENT_METHOD(state, action) {
    return {
        ...state,
        paymentMethods: state.paymentMethods.filter(method => method.paymentProfileId !== action.paymentProfileId)
    };
}

function DELETE_SAVED_ITEM(state, action) {
    const {id} = action;
    return {
        ...state,
        saved: state.saved.filter(item => item.saved_id !== id)
    };
}

function MODIFY_CART_ITEM(state, action) {
    const {cartItem} = action;
    return {...state, modifyCartItem: cartItem};
}

function SET_ADDRESSES(state, action) {
    const {addresses} = action;
    if (!addresses) {
        return state;
    }
    return {...state, addresses: addresses};
}

function SET_CART(state, action) {
    const {cartItems} = action;
    if (!cartItems) {
        return {...state, cart: []};
    }

    return {...state, cart: cartItems};
}

function SET_CONTACT_INFO(state, action) {
    const {contactInfo} = action;
    if (!contactInfo) {
        return state;
    }
    return {...state, contactInfo: contactInfo};
}

function SET_ORDERS(state, action) {
    const {orders} = action;
    if (!orders) {
        return state;
    }
    return {...state, orders: orders};
}

function SET_PAYMENT_METHODS(state, action) {
    const {paymentMethods} = action;
    if (!paymentMethods) {
        return state;
    }
    return {...state, paymentMethods: paymentMethods};
}

function SET_RECENT_ITEMS(state, action) {
    const {recentItems} = action;
    if (!recentItems) {
        return state;
    }
    return {...state, recentItems: recentItems};
}

function SET_SAVED_ITEMS(state, action) {
    const {savedItems} = action;
    if (!savedItems) {
        return {...state, saved: []};
    }
    return {...state, saved: savedItems};
}

function UPDATE_LOGGED_IN_STATUS(state, action) {
    const {loggedIn} = action;
    let {contactInfo} = state;
    if (loggedIn === false) {
        contactInfo = {};
    }
    return {...state, loggedIn, contactInfo};
}

function UPDATE_ADMIN_LOGGED_IN_STATUS(state, action) {
    const {adminLoggedIn} = action;
    return {...state, adminLoggedIn};
}

function SAVE_LAST_CART(state) {
    const {cart} = state;
    return {...state, cart: getNotAbleToCheckoutItems(cart), last_cart: getAbleToCheckoutItems(cart)};
}

function CLEAR_LAST_CART(state) {
    return {...state, last_cart: []};
}

function REPLACELAST_CART_EMAILS(state) {
    return replaceEmails(state);
}

function REPLACE_LAST_CART_PG_LINKS(state) {
    return convertHyperlinkToText(state);
}

const handlers = {};
handlers[actionTypes.DELETE_ADDRESS] = DELETE_ADDRESS;
handlers[actionTypes.DELETE_CART_ITEM] = DELETE_CART_ITEM;
handlers[actionTypes.DELETE_PAYMENT_METHOD] = DELETE_PAYMENT_METHOD;
handlers[actionTypes.DELETE_SAVED_ITEM] = DELETE_SAVED_ITEM;
handlers[actionTypes.MODIFY_CART_ITEM] = MODIFY_CART_ITEM;
handlers[actionTypes.SET_ADDRESSES] = SET_ADDRESSES;
handlers[actionTypes.SET_CART] = SET_CART;
handlers[actionTypes.SET_CONTACT_INFO] = SET_CONTACT_INFO;
handlers[actionTypes.SET_ORDERS] = SET_ORDERS;
handlers[actionTypes.SET_PAYMENT_METHODS] = SET_PAYMENT_METHODS;
handlers[actionTypes.SET_RECENT_ITEMS] = SET_RECENT_ITEMS;
handlers[actionTypes.SET_SAVED_ITEMS] = SET_SAVED_ITEMS;
handlers[actionTypes.UPDATE_LOGGED_IN_STATUS] = UPDATE_LOGGED_IN_STATUS;
handlers[actionTypes.UPDATE_ADMIN_LOGGED_IN_STATUS] = UPDATE_ADMIN_LOGGED_IN_STATUS;
handlers[actionTypes.SAVE_LAST_CART] = SAVE_LAST_CART;
handlers[actionTypes.CLEAR_LAST_CART] = CLEAR_LAST_CART;
handlers[actionTypes.REPLACE_LAST_CART_EMAILS] = REPLACELAST_CART_EMAILS;
handlers[actionTypes.REPLACE_LAST_CART_PG_LINKS] = REPLACE_LAST_CART_PG_LINKS;
export default createReducer(defaultState, handlers);
