/* @flow */
import '../shared/lib/lodashMixins';
import * as reducers from '../shared/reducers';
import createRoutes from '../shared/routes';
import React from 'react';
import ReactDOM from 'react-dom';
import routeEnterStore from '../shared/lib/routeEnterStore';
import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import {Router, browserHistory} from 'react-router';
import _ from 'lodash';
import axios from 'axios';
import {logError} from '../shared/log';
import is from 'is_js';
import {envMode} from '../shared/constants/common';
import {sendTrackEvent} from '../shared/lib/klaviyo';

const initialState = window.__INITIAL_STATE__;

window.addEventListener('unhandledrejection', e => {
    e.preventDefault();
    logError(e.reason, 'Unhandled promise rejection');
});

const serverRoot = initialState.config.serverRoot;
const reducer = combineReducers(reducers);
let createStoreWithMiddleware;
if (process.env.NODE_ENV === envMode.production) {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
} else {
    const createLogger = require('redux-logger').default;
    const logger = createLogger({collapsed: true});
    createStoreWithMiddleware = applyMiddleware(thunk, logger)(createStore);
}

const store = createStoreWithMiddleware(reducer, initialState);

if (!Array.prototype.find) {
    // TODO: ESLINT Looks like they are trying to write their own polyfill...
    // eslint-disable-next-line no-extend-native
    Array.prototype.find = function(predicate) {
        if (this === null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        const list = Object(this);
        const length = list.length >>> 0;
        const thisArg = arguments[1];
        let value;

        for (let i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}

if (!Array.prototype.findIndex) {
    // TODO: ESLINT Looks like they are trying to write their own polyfill...
    // eslint-disable-next-line no-extend-native
    Array.prototype.findIndex = function(predicate) {
        if (this === null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        const list = Object(this);
        const length = list.length >>> 0;
        const thisArg = arguments[1];
        let value;

        for (let i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
}

if (is.safari()) {
    document.documentElement.className += ' safari';
}

// We use this boolean to keep track of whether we need to fetch data needs of the route we
// are navigating to. Once the client side code has taken over, we will always want to let
// the EnterHook run. But for the initial route, the server has already fetched any data
// needs and rendered HTML. This works at the moment, but if multiple EnterHooks run for the
// initial route this won't work anymore.
let routeWasServerRendered = true;
const onEnter = (nextState, replaceState, callback) => {
    if (routeWasServerRendered) {
        routeWasServerRendered = false;
        callback();
    } else {
        const host = typeof window !== 'undefined' ? window.location.hostname : '';

        const axiosInstance = axios.create({
            headers: {
                domain: host
            }
        });

        routeEnterStore(host, store, true, serverRoot, axiosInstance, nextState, replaceState, callback)
            .then(() => {
                sendTrackEvent('addPageView', {url: window.location.pathname + window.location.search});
                if (typeof UET !== 'undefined') {
                    const o = {ti: '5797487'};
                    o.q = window.uetq;
                    window.uetq = new UET(o);
                    window.uetq.push('pageLoad');
                }
                if (typeof _bcvma !== 'undefined') {
                    _bcvma.push(['pageViewed', document.location.href, document.referrer]);
                    if (typeof bcLoad !== 'undefined') {
                        bcLoad();
                    }
                }
            })
            .catch(err => {
                logError(err);
                if (err.response && err.response.status === 401) {
                    if (_.startsWith(nextState.location.pathname.toLowerCase(), '/admin')) {
                        window.location.href = '/admin/login';
                    } else {
                        window.location.href = '/login';
                    }
                } else if (err.response && err.response.status === 404) {
                    window.location.href = '/404';
                } else {
                    window.location.href = '/500';
                }
                callback();
            });
    }
};

const routes = ({config: {wlConfig}}) => createRoutes(onEnter, wlConfig && Boolean(wlConfig.isPartner));

/* eslint-disable react/no-children-prop */
ReactDOM.render(
    <Provider store={store}>
        <Router children={routes(store.getState())} history={browserHistory} />
    </Provider>,
    document.getElementById('app')
);
