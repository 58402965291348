/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';
import {replacePrintglobeRelatedData} from '../lib/pdExcludes';

export const DEFAULT_PAGE_TITLE = 'PrintGlobe';
export const DEFAULT_PAGE_DESCRIPTION = 'PrintGlobe Description';

const defaultState = {
    analytics: [],
    accountPage: {
        tabNumber: 0
    },
    breadcrumbs: [],
    changePassword: {
        isOpen: false
    },
    confirmation: {
        isOpen: false,
        message: '',
        onConfirm: () => {},
        item_id: null
    },
    editAddress: {
        isOpen: false
    },
    editContactInfo: {
        isOpen: false
    },
    editPaymentMethod: {
        isOpen: false
    },
    loginFailed: {
        isOpen: false
    },
    modal: {
        isOpen: false
    },
    onLoginRoute: '/',
    orderSample: {
        isOpen: false
    },
    recentItems: {
        isOpen: false
    },
    resetPassword: {
        isOpen: false
    },
    savedItem: {
        isOpen: false
    },
    email: {
        isOpen: false
    },
    mobileTray: {
        isOpen: false,
        first: false
    },
    shopByCategory: {
        isOpen: false,
        mouseOver: false,
        timeoutID: null
    },
    documents: {},
    couponsPage: {},
    title: DEFAULT_PAGE_TITLE,
    description: DEFAULT_PAGE_DESCRIPTION,
    prev: null,
    next: null,
    canonical: null,
    ogTitle: null,
    ogUrl: null,
    ogImage: null,
    ogType: null,
    schema: null,
    faqQuestionId: null,
    disable_footer_strip: false,
    printing_page: null,
    printing_breadcrumbs: [],
    printing_title: null,
    errorCode: null,
    isPartnerDisabled: false,
    serverRendered: null,
    removeChat: false,
    isAdmin: false,
    host: '',
    skipScripts: false
};

const CLOSE_ALL_MODALS = state => ({
    ...state,
    changePassword: {...state.changePassword, isOpen: false},
    confirmation: {...state.confirmation, isOpen: false},
    editAddress: {...state.editAddress, isOpen: false},
    editContactInfo: {...state.editContactInfo, isOpen: false},
    editPaymentMethod: {...state.editPaymentMethod, isOpen: false},
    modal: {...state.modal, isOpen: false},
    needHelp: {...state.needHelp, isOpen: false},
    orderSample: {...state.orderSample, isOpen: false},
    resetPassword: {...state.orderSample, isOpen: false},
    recentItems: {...state.recentItems, isOpen: false},
    savedItem: {...state.savedItem, isOpen: false},
    email: {...state.email, isOpen: false},
    mobileTray: {...state.mobileTray, isOpen: false},
    shopByCategory: {...state.shopByCategory, isOpen: false}
});

const OPEN_CHANGE_PASSWORD_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    changePassword: {...state.changePassword, isOpen: true}
});

const OPEN_CONFIRMATION_MODAL = (state, action) => {
    const {message, onConfirm, item_id} = action;
    return {
        ...state,
        modal: {...state.modal, isOpen: true},
        confirmation: {...state.confirmation, isOpen: true, message, onConfirm, item_id}
    };
};

const OPEN_EMAIL_MODAL = state => ({...state, modal: {...state.modal, isOpen: true}, email: {...state.email, isOpen: true}});

const OPEN_EDIT_ADDRESS_MODAL = (state, action) => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    editAddress: {...state.editAddress, isOpen: true, initialAddress: action.initialAddress}
});

const OPEN_EDIT_CONTACT_INFO_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    editContactInfo: {...state.editContactInfo, isOpen: true}
});

const OPEN_EDIT_PAYMENT_METHOD_MODAL = (state, action) => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    editPaymentMethod: {...state.editPaymentMethod, isOpen: true, initalPaymentMethod: action.initalPaymentMethod}
});

const OPEN_RECENT_ITEMS_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    recentItems: {...state.recentItems, isOpen: true}
});

const OPEN_RESET_PASSWORD_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    resetPassword: {...state.resetPassword, isOpen: true}
});

const OPEN_SAVED_ITEM_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    savedItem: {...state.savedItem, isOpen: true}
});

const OPEN_LOGIN_FAILED_MODAL = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    loginFailed: {...state.loginFailed, isOpen: true}
});

const OPEN_MOBILE_TRAY = state => ({
    ...state,
    modal: {...state.modal, isOpen: true},
    mobileTray: {...state.mobileTray, isOpen: true}
});

const SET_FIRST_MOBILE_TRAY = state => ({...state, mobileTray: {...state.mobileTray, first: true}});

const CHANGE_SHOP_BY_CATEGORY = (state, action) => {
    const {isOpen, mouseOver, timeoutID} = action;
    const shopByCategory = {...state.shopByCategory};

    if (isOpen !== null) {
        shopByCategory.isOpen = isOpen;
    }

    if (mouseOver !== null) {
        shopByCategory.mouseOver = mouseOver;
    }

    if (timeoutID !== null) {
        shopByCategory.timeoutID = timeoutID;
    }

    return {...state, modal: {isOpen: isOpen !== null ? isOpen : state.modal.isOpen}, shopByCategory};
};

const SET_BREADCRUMB = (state, action) => {
    const {parentRows, object} = action;

    let breadcrumbs = [];
    //We need to keep the breadcrumb consistent
    if (state.breadcrumbs && state.breadcrumbs.length) {
        const newBreadcrumbs = parentRows.map(row => (object ? row.concat(object) : row)).find(parentRow => {
            for (let i = 0; i < state.breadcrumbs.length && i < parentRow.length; i++) {
                if (state.breadcrumbs[i].path !== parentRow[i].path) {
                    return false;
                }
            }
            return true;
        });
        if (newBreadcrumbs) {
            return {...state, breadcrumbs: newBreadcrumbs};
        }
    }
    if (parentRows.length > 0) {
        breadcrumbs = object ? parentRows[0].concat(object) : parentRows[0];
    } else {
        breadcrumbs = object ? [object] : [];
    }
    return {...state, breadcrumbs};
};

const SET_ON_LOGIN_ROUTE = (state, action) => {
    const {route} = action;
    if (!route) {
        return {...state, onLoginRoute: '/'};
    }
    return {...state, onLoginRoute: route};
};

const SET_ACCOUNT_PAGE_TAB = (state, action) => {
    const {tabNumber} = action;
    if (tabNumber === undefined) {
        return state;
    }
    return {...state, accountPage: {tabNumber: tabNumber}};
};

const PAGE_SET_TITLE = (state, action) => {
    const {title} = action;
    const {partnerBusinessName} = state;
    if (!title) {
        return state;
    }
    return {...state, title: partnerBusinessName || title};
};

const PAGE_SET_PARTNER_BUSINESS_NAME = (state, action) => {
    const {partnerBusinessName} = action;
    if (!partnerBusinessName) {
        return state;
    }
    return {...state, partnerBusinessName};
};

const PAGE_SET_DESCRIPTION = (state, action) => {
    const {description} = action;
    if (!description) {
        return state;
    }
    return {...state, description};
};

const ADD_PREV_AND_NEXT = (state, action) => {
    const {prev, next} = action;
    return {...state, prev, next};
};

const PAGE_SET_ERROR_CODE = (state, action) => ({...state, errorCode: action.errorCode});

const PAGE_SET_NO_PARTNER = (state, action) => ({...state, isPartnerDisabled: action.isPartnerDisabled});

const PAGE_SET_CANONICAL = (state, action) => ({...state, canonical: action.canonical});

const PAGE_SET_SCHEMA = (state, action) => ({...state, schema: action.schema});

const PAGE_SET_OPEN_GRAPH_TAGS = (state, action) => {
    const {ogTitle, ogUrl, ogImage, ogType} = action;
    return {...state, ogTitle, ogUrl, ogImage, ogType};
};

const PAGE_SET_SERVER_RENDERED = (state, action) => ({...state, serverRendered: action.serverRendered});

const PREFILL_FAQUESTION = (state, action) => ({...state, faqQuestionId: action.faqQuestionId});

const DISABLE_FOOTER_STRIP = state => ({...state, disable_footer_strip: true});

const SET_PRINTING_PAGE = (state, action) => {
    const {printing_breadcrumbs, printing_page, printing_title} = action;
    return {...state, printing_breadcrumbs, printing_page, printing_title};
};

const PAGE_SET_COUPONS = (state, action) => ({...state, couponsPage: action.couponsPage});

const REMOVE_CHAT = (state, action) => ({...state, removeChat: action.removeChat});

const REMOVE_CHAT_EMAILS = state => replaceEmails(state);

const REPLACE_CHAT_PG_LINKS = state => convertHyperlinkToText(state);

function REPLACE_PAGE_PG_RELATED_DATA(state, action) {
    const {documents, ...otherState} = state;

    return {...replacePrintglobeRelatedData(otherState, action.wlConfig), documents};
}

const SET_DOCUMENTS = (state, action) => ({...state, documents: action.documents});

const SET_IS_ADMIN = (state, action) => ({...state, isAdmin: action.isAdmin});

const SET_HOST = (state, action) => ({...state, host: action.host});

const SET_SCRIPTS = (state, action) => ({...state, skipScripts: action.skipScripts});

const handlers = {};
handlers[actionTypes.CLOSE_ALL_MODALS] = CLOSE_ALL_MODALS;
handlers[actionTypes.OPEN_CHANGE_PASSWORD_MODAL] = OPEN_CHANGE_PASSWORD_MODAL;
handlers[actionTypes.OPEN_CONFIRMATION_MODAL] = OPEN_CONFIRMATION_MODAL;
handlers[actionTypes.OPEN_EMAIL_MODAL] = OPEN_EMAIL_MODAL;
handlers[actionTypes.OPEN_EDIT_ADDRESS_MODAL] = OPEN_EDIT_ADDRESS_MODAL;
handlers[actionTypes.OPEN_EDIT_CONTACT_INFO_MODAL] = OPEN_EDIT_CONTACT_INFO_MODAL;
handlers[actionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL] = OPEN_EDIT_PAYMENT_METHOD_MODAL;
handlers[actionTypes.OPEN_RECENT_ITEMS_MODAL] = OPEN_RECENT_ITEMS_MODAL;
handlers[actionTypes.OPEN_RESET_PASSWORD_MODAL] = OPEN_RESET_PASSWORD_MODAL;
handlers[actionTypes.OPEN_SAVED_ITEM_MODAL] = OPEN_SAVED_ITEM_MODAL;
handlers[actionTypes.OPEN_LOGIN_FAILED_MODAL] = OPEN_LOGIN_FAILED_MODAL;
handlers[actionTypes.CHANGE_SHOP_BY_CATEGORY] = CHANGE_SHOP_BY_CATEGORY;
handlers[actionTypes.SET_ACCOUNT_PAGE_TAB] = SET_ACCOUNT_PAGE_TAB;
handlers[actionTypes.SET_BREADCRUMB] = SET_BREADCRUMB;
handlers[actionTypes.SET_ON_LOGIN_ROUTE] = SET_ON_LOGIN_ROUTE;
handlers[actionTypes.PAGE_SET_ERROR_CODE] = PAGE_SET_ERROR_CODE;
handlers[actionTypes.PAGE_SET_NO_PARTNER] = PAGE_SET_NO_PARTNER;
handlers[actionTypes.PAGE_SET_TITLE] = PAGE_SET_TITLE;
handlers[actionTypes.PAGE_SET_PARTNER_BUSINESS_NAME] = PAGE_SET_PARTNER_BUSINESS_NAME;
handlers[actionTypes.PAGE_SET_DESCRIPTION] = PAGE_SET_DESCRIPTION;
handlers[actionTypes.ADD_PREV_AND_NEXT] = ADD_PREV_AND_NEXT;
handlers[actionTypes.PAGE_SET_CANONICAL] = PAGE_SET_CANONICAL;
handlers[actionTypes.PAGE_SET_OPEN_GRAPH_TAGS] = PAGE_SET_OPEN_GRAPH_TAGS;
handlers[actionTypes.PAGE_SET_SERVER_RENDERED] = PAGE_SET_SERVER_RENDERED;
handlers[actionTypes.PREFILL_FAQUESTION] = PREFILL_FAQUESTION;
handlers[actionTypes.DISABLE_FOOTER_STRIP] = DISABLE_FOOTER_STRIP;
handlers[actionTypes.SET_PRINTING_PAGE] = SET_PRINTING_PAGE;
handlers[actionTypes.OPEN_MOBILE_TRAY] = OPEN_MOBILE_TRAY;
handlers[actionTypes.SET_FIRST_MOBILE_TRAY] = SET_FIRST_MOBILE_TRAY;
handlers[actionTypes.REMOVE_CHAT] = REMOVE_CHAT;
handlers[actionTypes.PAGE_SET_SCHEMA] = PAGE_SET_SCHEMA;
handlers[actionTypes.PAGE_SET_COUPONS] = PAGE_SET_COUPONS;
handlers[actionTypes.REPLACE_CHAT_EMAILS] = REMOVE_CHAT_EMAILS;
handlers[actionTypes.REPLACE_CHAT_PG_LINKS] = REPLACE_CHAT_PG_LINKS;
handlers[actionTypes.REPLACE_PAGE_PG_RELATED_DATA] = REPLACE_PAGE_PG_RELATED_DATA;
handlers[actionTypes.SET_DOCUMENTS] = SET_DOCUMENTS;
handlers[actionTypes.SET_IS_ADMIN] = SET_IS_ADMIN;
handlers[actionTypes.SET_HOST] = SET_HOST;
handlers[actionTypes.SET_SCRIPTS] = SET_SCRIPTS;
export default createReducer(defaultState, handlers);
