/* @flow */
import * as actionTypes from '../actionTypes';
import {logError} from '../log';
export function pageSetServerRendered(serverRendered) {
    return {
        type: actionTypes.PAGE_SET_SERVER_RENDERED,
        serverRendered
    };
}

export function closeAllModals() {
    return {
        type: actionTypes.CLOSE_ALL_MODALS
    };
}

export function openChangePasswordModal() {
    return {
        type: actionTypes.OPEN_CHANGE_PASSWORD_MODAL
    };
}

export function openEmailModal() {
    return {
        type: actionTypes.OPEN_EMAIL_MODAL
    };
}

export function openConfirmationModal(onConfirm, message, item_id) {
    return {
        type: actionTypes.OPEN_CONFIRMATION_MODAL,
        onConfirm,
        message,
        item_id
    };
}

export function openEditAddressModal(initialAddress) {
    return {
        type: actionTypes.OPEN_EDIT_ADDRESS_MODAL,
        initialAddress
    };
}

export function openEditContactInfoModal() {
    return {
        type: actionTypes.OPEN_EDIT_CONTACT_INFO_MODAL
    };
}

export function openEditPaymentMethodModal() {
    return {
        type: actionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL
    };
}

export function openRecentItemsModal() {
    return {
        type: actionTypes.OPEN_RECENT_ITEMS_MODAL
    };
}

export function openResetPasswordModal() {
    return {
        type: actionTypes.OPEN_RESET_PASSWORD_MODAL
    };
}

export function openSavedItemModal() {
    return {
        type: actionTypes.OPEN_SAVED_ITEM_MODAL
    };
}

export function openMobileTray() {
    return {
        type: actionTypes.OPEN_MOBILE_TRAY
    };
}

export function setFirstMobileTray() {
    return {
        type: actionTypes.SET_FIRST_MOBILE_TRAY
    };
}

export function changeShopByCategory(isOpen, mouseOver, timeoutID) {
    return {
        type: actionTypes.CHANGE_SHOP_BY_CATEGORY,
        isOpen,
        mouseOver,
        timeoutID
    };
}

export function setBreadcrumb(parentRows, object = undefined) {
    return {
        type: actionTypes.SET_BREADCRUMB,
        parentRows,
        object
    };
}

export function setOnLoginRoute(route) {
    return {
        type: actionTypes.SET_ON_LOGIN_ROUTE,
        route
    };
}

export const ACCOUNT_PROFILE = 0;
export const SAVED_ITEMS = 1;
export const ORDERS_AND_INVOICES = 2;

export function setAccountPageTab(tabNumber) {
    return {
        type: actionTypes.SET_ACCOUNT_PAGE_TAB,
        tabNumber
    };
}

export function pageSetErrorCode(errorCode = undefined) {
    return {
        type: actionTypes.PAGE_SET_ERROR_CODE,
        errorCode
    };
}

export function pageSetNoPartner(isPartnerDisabled) {
    return {
        type: actionTypes.PAGE_SET_NO_PARTNER,
        isPartnerDisabled
    };
}

export function pageSetTitle(title) {
    return {
        type: actionTypes.PAGE_SET_TITLE,
        title
    };
}

export function pageSetDefaultBusinessName(partnerBusinessName) {
    return {
        type: actionTypes.PAGE_SET_PARTNER_BUSINESS_NAME,
        partnerBusinessName
    };
}

export function pageSetDescription(description) {
    return {
        type: actionTypes.PAGE_SET_DESCRIPTION,
        description
    };
}

export function pageSetSchema(schema) {
    return {
        type: actionTypes.PAGE_SET_SCHEMA,
        schema
    };
}

export function addPrevAndNext(prev, next) {
    return {
        type: actionTypes.ADD_PREV_AND_NEXT,
        prev,
        next
    };
}

export function pageSetCanonical(canonical) {
    return {
        type: actionTypes.PAGE_SET_CANONICAL,
        canonical
    };
}

export function pageSetPaginationHeaderLinks(nextState) {
    return dispatch => {
        const pg = parseInt(nextState.location.query.pg);
        if (!isNaN(pg) && pg > 1) {
            dispatch(addPrevAndNext(`${nextState.location.pathname}?pg=${pg - 1}`, `${nextState.location.pathname}?pg=${pg + 1}`));
            dispatch(pageSetCanonical(`${nextState.location.pathname.toLowerCase()}?pg=${pg}`));
        } else {
            dispatch(pageSetCanonical(`${nextState.location.pathname}`));
            dispatch(addPrevAndNext(null, `${nextState.location.pathname.toLowerCase()}?pg=2`));
        }
    };
}

export function pageSetOpenGraphTags(ogTitle, ogUrl, ogImage, ogType) {
    return {
        type: actionTypes.PAGE_SET_OPEN_GRAPH_TAGS,
        ogTitle,
        ogUrl,
        ogImage,
        ogType
    };
}

export function prefillFAQuestion(faqQuestionId) {
    return {
        type: actionTypes.PREFILL_FAQUESTION,
        faqQuestionId: faqQuestionId
    };
}

export function disableFooterStrip() {
    return {
        type: actionTypes.DISABLE_FOOTER_STRIP
    };
}

export function setPrintingPage(printing_breadcrumbs, printing_title, printing_page) {
    return {
        type: actionTypes.SET_PRINTING_PAGE,
        printing_breadcrumbs,
        printing_page,
        printing_title
    };
}

export function getPrintingPage(nextState, serverRoot, axios) {
    const {printing_page_url} = nextState.params;
    return dispatch =>
        axios
            .get(`${serverRoot}/api/printing_pages/${printing_page_url}`)
            .then(response => {
                const {printing_page, breadcrumbs, title, meta_title, meta_description} = response.data;
                dispatch(pageSetTitle(meta_title));
                dispatch(pageSetDescription(meta_description));
                dispatch(
                    setPrintingPage(
                        breadcrumbs.map(b => ({
                            ...b,
                            path: b.path.replace(/http:\/\/www\.printglobe\.com/g, '')
                        })),
                        title,
                        printing_page
                            ? printing_page
                                  .replace(/http:\/\/www\.printglobe\.com/g, '')
                                  .replace(/<\/a>, <a/g, '</a><a')
                                  .replace(/&#xBB;/g, '')
                                  .replace(/result_price">(\$)/g, 'result_price">')
                                  .replace(/- Minimum Quantity/g, '• Minimum Quantity')
                                  .replace(/pieces<\/p>\s+<\/div>/g, '</p></div>')
                                  .replace(/View Details &gt;/g, 'View Details')
                            : ''
                    )
                );
            })
            .catch(err => {
                logError(err, 'Get printing page failed');
                if (err.response) {
                    dispatch(pageSetErrorCode(err.response.status === 500 ? 500 : 404));
                } else {
                    throw err;
                }
            });
}

export function getCouponsPage(nextState, serverRoot, axios) {
    return dispatch =>
        axios
            .get(`${serverRoot}/api/coupons`)
            .then(response => {
                const couponsPage = response.data;
                return dispatch(setCouponsPage(couponsPage));
            })
            .catch(err => {
                logError(err, 'Get coupons page failed');
                if (err.response) {
                    dispatch(pageSetErrorCode(err.response.status === 500 ? 500 : 404));
                } else {
                    throw err;
                }
            });
}

export function setDocument(documents) {
    return {
        type: actionTypes.SET_DOCUMENTS,
        documents
    };
}

export function setCouponsPage(couponsPage) {
    return {
        type: actionTypes.PAGE_SET_COUPONS,
        couponsPage
    };
}

export function pageRemoveChat(removeChat) {
    return {
        type: actionTypes.REMOVE_CHAT,
        removeChat
    };
}

export function openLoginFailedModal() {
    return {
        type: actionTypes.OPEN_LOGIN_FAILED_MODAL
    };
}

export function setIsAdmin(isAdmin) {
    return {
        type: actionTypes.SET_IS_ADMIN,
        isAdmin
    };
}

export function setHost(host) {
    return {
        type: actionTypes.SET_HOST,
        host
    };
}

export function setSkipScripts(skipScripts) {
    return {
        type: actionTypes.SET_SCRIPTS,
        skipScripts
    };
}
