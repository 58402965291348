/* @flow */
import request from 'axios';
import * as actionTypes from '../actionTypes';
import {calculateSubtotal, calculateTotal} from '../lib/calculate';
import {
    getCart as getAccountCart,
    getOrders as getAccountOrders,
    getSavedItems as getAccountSavedItems,
    getRecentItems as getAccountRecentItems
} from '../services/account';
import {logError} from '../log';
import {getRootUrl} from '../helpers/window';
import * as analytics from '../lib/analytics';

export function updateLoggedInStatus(loggedIn) {
    return {
        type: actionTypes.UPDATE_LOGGED_IN_STATUS,
        loggedIn
    };
}

export const updateAdminLoggedInStatus = adminLoggedIn => ({
    type: actionTypes.UPDATE_ADMIN_LOGGED_IN_STATUS,
    adminLoggedIn
});
//TODO create CartItem type
export function setCart(cartItems, rootUri) {
    return {
        type: actionTypes.SET_CART,
        rootUri,
        cartItems: cartItems.map(cartItem => {
            const {quantity, product, addons, imprint_colors, surface_color, is_sample_product} = cartItem;
            const option = product.options.find(o => o.id === cartItem.product_option_id);
            const isAbleToCheckout = Boolean(option);

            return {
                ...cartItem,
                isAbleToCheckout,
                subtotal: isAbleToCheckout
                    ? parseFloat(calculateSubtotal(quantity, option, product, addons, imprint_colors, surface_color, is_sample_product, product.discount))
                    : 0,
                total: isAbleToCheckout
                    ? parseFloat(calculateTotal(quantity, option, product, addons, imprint_colors, surface_color, is_sample_product, product.discount))
                    : 0
            };
        })
    };
}

export function getCart(nextState, serverRoot, axios) {
    return (dispatch, getState) => {
        const defaultRootUrl = getState().config.publicServerRoot;
        const rootUrl = getRootUrl() || defaultRootUrl;

        if (!getState().profile.loggedIn) {
            return dispatch(setCart([], rootUrl));
        }
        return getAccountCart(axios, serverRoot)
            .then(response => dispatch(setCart(response.data, rootUrl)))
            .catch(err => {
                logError(err, 'Get cart failed');
            });
    };
}

export function setRecentItems(recentItems) {
    return {
        type: actionTypes.SET_RECENT_ITEMS,
        recentItems
    };
}

export function getRecentItems(nextState, serverRoot, axios) {
    return dispatch =>
        getAccountRecentItems(axios, serverRoot)
            .then(response => dispatch(setRecentItems(response.data)))
            .catch(err => {
                logError(err, 'Get recent items failed');
            });
}

export function deleteCartItem(id) {
    return (dispatch, getState) =>
        request
            .post('/api/account/deleteCartItem', {id})
            .then(() =>
                dispatch({
                    type: actionTypes.DELETE_CART_ITEM,
                    id,
                    rootUri: getState().config.publicServerRoot
                })
            )
            .catch(err => {
                logError(err, 'add cart item failed');
            });
}

export function modifyCartItem(cartItem) {
    return {
        type: actionTypes.MODIFY_CART_ITEM,
        cartItem
    };
}

export function setAddresses(addresses) {
    return {
        type: actionTypes.SET_ADDRESSES,
        addresses
    };
}

export function getAddresses(nextState, serverRoot, axios) {
    return (dispatch, getState) => {
        if (!getState().profile.loggedIn) {
            return Promise.resolve({});
        }
        return axios
            .get(`${serverRoot}/api/account/addresses`)
            .then(response => dispatch(setAddresses(response.data)))
            .catch(err => {
                logError(err, 'Get addresses failed');
            });
    };
}

export function deleteAddress(id) {
    request.post('/api/account/deleteAddress', {id: id}).catch(err => {
        logError(err, 'Delete address failed');
    });
    return {
        type: actionTypes.DELETE_ADDRESS,
        id
    };
}

export function defaultAddress(id) {
    return dispatch =>
        request
            .post('/api/account/defaultAddress', {id})
            .then(response => dispatch(setAddresses(response.data)))
            .catch(err => {
                logError(err, 'Get addresses failed');
            });
}

export function setPaymentMethods(paymentMethods) {
    return {
        type: actionTypes.SET_PAYMENT_METHODS,
        paymentMethods
    };
}

export function getPaymentMethods(nextState, serverRoot, axios) {
    return (dispatch, getState) => {
        if (!getState().profile.loggedIn) {
            return Promise.resolve({});
        }
        return axios
            .get(`${serverRoot}/api/account/paymentMethods`)
            .then(response => dispatch(setPaymentMethods(response.data)))
            .catch(err => {
                logError(err, 'Get Payment Methods failed');
            });
    };
}
//TODO create PaymentMethods type
export function defaultPaymentMethod(id, paymentMethods) {
    return dispatch =>
        request
            .post('/api/account/defaultPaymentMethod', {id})
            .then(() =>
                dispatch(
                    setPaymentMethods(
                        paymentMethods.sort((a, b) => {
                            if (b.paymentProfileId === id) {
                                return 1;
                            }
                            return -1;
                        })
                    )
                )
            )
            .catch(err => {
                logError(err, 'Get addresses failed');
            });
}

export function deletePaymentMethod(paymentProfileId) {
    request.post('/api/account/deletePaymentMethod', {id: paymentProfileId}).catch(err => {
        logError(err, 'Delete payment method failed');
    });
    return {
        type: actionTypes.DELETE_PAYMENT_METHOD,
        paymentProfileId
    };
}

export function setSavedItems(savedItems) {
    return {
        type: actionTypes.SET_SAVED_ITEMS,
        savedItems
    };
}

export function getSavedItems(nextState, serverRoot, axios) {
    return dispatch =>
        getAccountSavedItems(axios, serverRoot)
            .then(response => dispatch(setSavedItems(response.data)))
            .catch(err => {
                logError(err, 'Get Saved Items failed');
            });
}

export function deleteSavedItem(id) {
    request.post('/api/account/deleteSavedItem', {id}).catch(err => {
        logError(err, 'delete saved item failed');
    });
    return {
        type: actionTypes.DELETE_SAVED_ITEM,
        id
    };
}

export function setContactInfo(contactInfo) {
    return {
        type: actionTypes.SET_CONTACT_INFO,
        contactInfo
    };
}

export function getContactInfo(nextState, serverRoot, axios) {
    return (dispatch, getState) => {
        if (!getState().profile.loggedIn) {
            return Promise.resolve({});
        }
        return axios
            .get(`${serverRoot}/api/account/contactInfo`)
            .then(response => {
                analytics.setContactInfo(response.data);
                return dispatch(setContactInfo(response.data));
            })
            .catch(err => {
                logError(err, 'Get Contact Info failed');
            });
    };
}

export function setOrders(orders) {
    return {
        type: actionTypes.SET_ORDERS,
        orders
    };
}

export function getOrders(nextState, serverRoot, axios) {
    return (dispatch, getState) => {
        if (!getState().profile.loggedIn) {
            return Promise.resolve({});
        }
        return getAccountOrders(axios, serverRoot)
            .then(response => dispatch(setOrders(response.data)))
            .catch(err => {
                logError(err, 'Get Orders failed');
            });
    };
}

export function saveLastCart() {
    return {
        type: actionTypes.SAVE_LAST_CART
    };
}

export function clearLastCart() {
    return {
        type: actionTypes.CLEAR_LAST_CART
    };
}
