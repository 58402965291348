/* @flow */
import parseConfigs from './parseConfigs';
import * as pageActions from './../actions/pageActions';
import {DEFAULT_PAGE_TITLE} from './../reducers/pageReducer';
import {logError} from '../log';
import {getPartner} from '../actions/partnersActions';
import * as profileActions from '../actions/profileActions';
import {replacePGRelatedData} from '../actions/generalActions';

// axiosInstance is uses for passing cookies before client rendering.
export default function routeEnterStore(host, store, isClient, serverRoot, axiosInstance, nextState, replaceState, callback) {
    store.dispatch(pageActions.pageSetCanonical(nextState.location.pathname.toLowerCase()));
    store.dispatch(pageActions.pageSetServerRendered(!isClient));

    const isPartner = store.getState().config.wlConfig.isPartner || (host && !host.includes(serverRoot));

    const getComponents = nextState.routes
        .map(route => route.getComponent)
        .filter(func => func !== undefined && func !== null)
        .map(
            func =>
                new Promise((resolve, reject) =>
                    func(nextState.location, (err, components) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(components);
                        }
                    })
                )
        );

    const componentsConfiguration = () =>
        Promise.all(getComponents)
            .then(components => {
                const config = parseConfigs(components);
                if (config.requireServerRender === true && isClient === true) {
                    window.location.href = nextState.location.pathname;
                    callback();
                    return null;
                }

                if (config.requireNoChat === true) {
                    store.dispatch(pageActions.pageRemoveChat(true));
                }

                const promises = config.needs.map(need => need && store.dispatch(need({...nextState, isClient}, serverRoot, axiosInstance))).filter(Boolean);
                store.dispatch(pageActions.closeAllModals());

                return Promise.all([config, ...promises]);
            })
            .then(result => {
                if (result) {
                    const [config] = result;
                    if (config.requireCartNonEmpty === true && store.getState().profile.cart.length === 0) {
                        replaceState(null, '/cart');
                    }
                }

                const nextTitle = store.getState().page.title;
                if (isClient === true) {
                    document.title = nextTitle ? nextTitle : DEFAULT_PAGE_TITLE;
                }
                if (isPartner) {
                    store.dispatch(profileActions.getContactInfo(nextState, serverRoot, axiosInstance));
                    store.dispatch(replacePGRelatedData());
                }
                callback();
                return null;
            })
            .catch(err => {
                logError(err);
                if (isClient) {
                    throw err;
                } else {
                    callback(err);
                }
            });

    return isPartner ? store.dispatch(getPartner(serverRoot, axiosInstance, host)).then(componentsConfiguration) : componentsConfiguration();
}
