/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';

const defaultState = {
    aggregations: [],
    products: [],
    total: 0,
    params: {}
};

function SET_SEARCH_RESULTS(state, action) {
    let {products, total, aggregations, params} = action;
    return {...state, products, total, aggregations, params};
}

function SET_SEARCH_PARAMETERS(state, action) {
    const {params} = action;
    if (!params) {
        return state;
    }
    return {...state, params};
}

function CLEAR_SEARCH() {
    return defaultState;
}

function REPLACE_SEARCH_EMAILS(state) {
    return replaceEmails(state);
}

function REPLACE_SEARCH_PG_LINKS(state) {
    return convertHyperlinkToText(state);
}

const handlers = {};
handlers[actionTypes.SET_SEARCH_RESULTS] = SET_SEARCH_RESULTS;
handlers[actionTypes.SET_SEARCH_PARAMETERS] = SET_SEARCH_PARAMETERS;
handlers[actionTypes.CLEAR_SEARCH] = CLEAR_SEARCH;
handlers[actionTypes.REPLACE_SEARCH_EMAILS] = REPLACE_SEARCH_EMAILS;
handlers[actionTypes.REPLACE_SEARCH_PG_LINKS] = REPLACE_SEARCH_PG_LINKS;
export default createReducer(defaultState, handlers);
