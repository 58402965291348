import {logError, logMessage} from '../log';

export const sendTrackEvent = (eventName, options) => {
    setTimeout(() => {
        if (typeof window !== 'undefined' && typeof window.klaviyo !== 'undefined') {
            window.klaviyo.push(['track', eventName, options]);
        }
    });
};

export const sendIdentifyEvent = options => {
    setTimeout(() => {
        if (typeof window !== 'undefined' && typeof window.klaviyo !== 'undefined') {
            window.klaviyo
                .identify(options)
                .then(() => logMessage('Identify has been completed'))
                .catch(e => logError(e, 'Identify failed'));
        }
    });
};
