import calculateUnitPrice from './calculateUnitPrice';
import {minQuantityPerBox} from '../constants/sampleProduct';
import _ from 'lodash';
import {getSampleShippingFee} from '../helpers/shipping';
import {getPriceWithDiscount} from '../helpers/product';

export const getCustomPMSFee = (imprint_colors, product, option) => {
    const nonCustomChosenImprints = imprint_colors.filter(color => color.id).length;
    let totalImprintsForOption;
    if (product.price_model === 'per color per side') {
        totalImprintsForOption = option.side_1_colors + option.side_2_colors;
    } else {
        totalImprintsForOption = option.side_1_colors;
    }

    const [customImprintColor] = imprint_colors.filter(color => !color.id);
    if (customImprintColor) {
        return customImprintColor.upcharge * (totalImprintsForOption - nonCustomChosenImprints);
    } else {
        return 0;
    }
};

export const getSetupFee = (product, option, addons = []) => {
    const addonSetupFee = _(addons)
        .filter(addon => addon.setup_fee && addon.optional === true)
        .map(addon => Number(addon.setup_fee))
        .value()
        .reduce((total, current) => total + current, 0);

    const baseSetupFee = Number(option.flat_setup_fee) > 0 ? Number(option.flat_setup_fee) : Number(product.setup_fee) * option.setups;

    return baseSetupFee + addonSetupFee;
};

export const calculateAddonPrice = (addon, quantities, quantity) => {
    let upcharges = 0;
    if (addon.addon_type === 'complex') {
        const productOptionsUpcharges = _(addon.palettes)
            .filter(p => p.option || p.palette_option_id)
            .map(p => {
                if (p.option) {
                    return p.option;
                } else {
                    if (p.palette_type === 'extra') {
                        return p.options.find(o => o.id === p.palette_option_id);
                    } else if (p.palette_type === 'surface') {
                        return p.surface_colors.find(sc => sc.id === p.palette_option_id);
                    } else if (p.palette_type === 'imprint') {
                        return p.imprint_colors.find(ic => ic.id === p.palette_option_id);
                    }
                }
            })
            .map(o => o.upcharge)
            .value();

        upcharges = productOptionsUpcharges.reduce((total, current) => total + Number(current), 0);
    }

    if (!addon.prices) {
        return upcharges;
    }

    if (addon.price_model === 'constant' || quantity < quantities[0]) {
        return addon.prices[0] + upcharges;
    }
    for (let i = 1; i < quantities.length; i++) {
        if (quantities[i] > quantity) {
            return addon.prices[i - 1] + upcharges;
        }
    }
    return addon.prices[quantities.length - 1] + upcharges;
};

export const calculateSalesTax = (total, state) => {
    if (state === 'TX') {
        return total * 0.0825;
    }
    if (state === 'OH') {
        return total * 0.0875;
    }
    if (state === 'KS') {
        return total * 0.105;
    }
    if (state === 'CA') {
        return total * 0.0725;
    }
    if (state === 'CO') {
        return total * 0.029;
    }
    if (state === 'WA') {
        return total * 0.0625;
    }
    if (state === 'FL') {
        return total * 0.06;
    }
    return 0;
};

const calculateSurfaceColorTotal = surfaceColor => (!_.isEmpty(surfaceColor) ? Number(surfaceColor.upcharge) : 0);

const sampleProductIsFree = units => units > 999;

const calculateSubtotalForSampleProduct = (units, prices, surfaceColor, discount) =>
    sampleProductIsFree(units) ? 0 : getPriceWithDiscount(prices[prices.length - 1], discount) + calculateSurfaceColorTotal(surfaceColor);

export const calculateSubtotal = (quantity, option, product, addons = [], imprint_colors = [], surface_color, isSampleProduct = false, discount = 0) => {
    if (isSampleProduct) {
        return calculateSubtotalForSampleProduct(product.price_units, option.prices, surface_color, discount);
    }

    const {quantities, special, price_units} = product;
    const unitPrice = calculateUnitPrice(quantity, option, quantities, special, discount);
    let addonPrices = 0;
    if (!_.isEmpty(addons)) {
        addonPrices = addons.map(addon => calculateAddonPrice(addon, quantities, quantity)).reduce((total, current) => total + parseFloat(current), 0);
    }

    let imprintColorTotal = 0;
    if (!_.isEmpty(imprint_colors)) {
        const imprintColorUpcharges = _(imprint_colors)
            .filter(color => color.id)
            .map(color => color.upcharge)
            .value();
        imprintColorTotal = imprintColorUpcharges.reduce((total, current) => total + Number(current), 0);
    }

    const surfaceColorTotal = calculateSurfaceColorTotal(surface_color);

    return (
        ((Number(unitPrice.toString()) + Number(addonPrices) + Number(imprintColorTotal) + Number(surfaceColorTotal)) * Number(quantity)) /
        Number(price_units)
    ).toFixed(2);
};

export const getHazmatFee = (product, quantity) => {
    if (product.apply_vendor_hazmat_fee === true) {
        return Number(product.vendor.hazmat_fee) * Math.ceil(quantity / (product.quantity_per_box || minQuantityPerBox));
    } else {
        return 0;
    }
};

export const calculateTotal = (quantity, option, product, addons = [], imprint_colors = [], surface_color, isSampleProduct = false, discount = 0) => {
    const subtotal = Number(calculateSubtotal(quantity, option, product, addons, imprint_colors, surface_color, isSampleProduct, discount));
    const total = isSampleProduct
        ? subtotal
        : subtotal + getCustomPMSFee(imprint_colors, product, option) + getSetupFee(product, option, addons) + getHazmatFee(product, quantity);

    return total.toFixed(2);
};

export const getMaxCouponDiscount = (couponDiscount, maxDiscountAmount) => {
    const maxDiscount = Number(maxDiscountAmount);
    if (maxDiscount && couponDiscount > maxDiscount) {
        return maxDiscount;
    }

    return couponDiscount;
};

export const calculateItemTotal = (cartitem, coupon) => {
    let couponDiscount = 0;
    if (coupon) {
        if (coupon.discount_scheme === 'percent') {
            if (!coupon.applicable_product_ids || !coupon.applicable_product_ids.length || coupon.applicable_product_ids.indexOf(cartitem.product.id) >= 0) {
                couponDiscount = getMaxCouponDiscount((cartitem.total * Number(coupon.discount)) / 100, coupon.max_discount_amount);
            }
        } else {
            if (!coupon.applicable_product_ids || !coupon.applicable_product_ids.length || coupon.applicable_product_ids.indexOf(cartitem.product.id) >= 0) {
                couponDiscount = Number(coupon.discount);
            }
        }
    }
    return cartitem.total - couponDiscount;
};

export const calculateOrderTotal = (items, shippingRates, coupon, isPartner, taxExempt) => {
    let couponDiscount = 0;
    if (coupon) {
        if (coupon.discount_scheme === 'percent') {
            let applicableItems;
            if (coupon.applicable_product_ids) {
                applicableItems = items.filter(i => _.includes(coupon.applicable_product_ids, i.product.id));
            } else {
                applicableItems = items;
            }
            const applicableItemsTotal = applicableItems.reduce((previous, current) => previous + current.total, 0);
            couponDiscount = getMaxCouponDiscount((applicableItemsTotal * Number(coupon.discount)) / 100, coupon.max_discount_amount);
        } else {
            if (coupon.applicable_product_ids) {
                let applicableItems = items.filter(i => _.includes(coupon.applicable_product_ids, i.product.id));
                if (!_.isEmpty(applicableItems)) {
                    couponDiscount = Number(coupon.discount);
                }
            } else {
                couponDiscount = Number(coupon.discount);
            }
        }
    }
    couponDiscount = Math.round(couponDiscount * 100) / 100;
    let salesTax = 0;
    let totalShipping = 0;
    let total = items.reduce((previous, current) => {
        let shipping_fee = 0;
        if (shippingRates) {
            const shippingRow = shippingRates.find(p => p.cart_id === current.id);
            const estimatedShipping = shippingRow ? shippingRow.rate : undefined;
            shipping_fee = isNaN(estimatedShipping) ? 0 : estimatedShipping;
            totalShipping += shipping_fee;
        }
        if (current.is_sample_product) {
            shipping_fee = getSampleShippingFee(isPartner);
            totalShipping += shipping_fee;
        }
        salesTax += !taxExempt ? Math.round((current.sales_tax ? current.sales_tax : 0) * 100) / 100 : 0;
        return previous + Number(current.total) + shipping_fee;
    }, 0);
    total = total - couponDiscount;
    return {
        subtotal: total,
        total: (Math.round((total + salesTax) * 100) / 100).toFixed(2),
        salesTax,
        shipping: totalShipping,
        couponDiscount: couponDiscount.toFixed(2)
    };
};

export function calculateDefaultProductSetupFee(product) {
    return product.setup_fee * product.options[0].setups;
}
