import _, {isNumber} from 'lodash';
import {countries} from 'country-data';
import {freeShippingFeatureId} from '../constants/product';
import {sampleProductShippingFee} from '../constants/sampleProduct';

export const getCountries = () =>
    _(countries)
        .map(country => ({value: country.name, label: country.name}))
        .filter(c => c.value && c.label)
        .sortBy('label')
        .uniq('value')
        .value();

export function hasFreeShippingFeature(features) {
    return Boolean(features.find(feature => feature.id === freeShippingFeatureId));
}

export function getSampleShippingFee(isPartner) {
    return isPartner ? sampleProductShippingFee.partner : sampleProductShippingFee.main;
}

export function getEstimatedShipping(item, shippingMethod, isPartner) {
    if (item.is_sample_product) {
        return getSampleShippingFee(isPartner);
    }
    const shippingRow = shippingMethod.products.find(p => p.cart_id === item.id);

    return shippingRow && isNumber(shippingRow.rate) && shippingRow.rate;
}

export const mapDefaultWlShippingAddressToUpsAddress = address => ({
    ...address,
    line1: address.address1,
    line2: address.address2
});
