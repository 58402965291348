/* @flow */
import _ from 'lodash';

export default function parseConfigs(components) {
    const configs = _(components)
        .map(c => {
            if (typeof c === 'function') {
                return c;
            } else if (typeof c === 'object') {
                return _.map(c, v => v);
            }
        })
        .flatten()
        .map(c => [c.config].concat((c.WrappedComponent ? c.WrappedComponent.config : null) || []))
        .flatten()
        .filter(x => x !== null && x !== undefined)
        .value();

    const requireServerRender = _.some(configs, c => c.requireServerRender);
    const requireCartNonEmpty = _.some(configs, c => c.requireCartNonEmpty);
    const requireNoChat = _.some(configs, c => c.requireNoChat);

    const needs = _(configs)
        .map(c => c.needs)
        .filter()
        .flatten()
        .uniq()
        .value();

    return {
        needs,
        requireServerRender,
        requireCartNonEmpty,
        requireNoChat
    };
}
