import marked from 'marked';
import {defaultScriptType} from '../constants/common';
import {cookieScriptTypes} from '../constants/cookies';
import {anyHttpRegexp, scriptTagAttrsRegexp, scriptTagDataAttrRegexp} from '../constants/regex';
import {rotatingWidget} from '../constants/shopperApproved';

export function changeContentScrollState(shouldLock) {
    if (!document) {
        return;
    }

    const htmlElement = document.getElementsByTagName('html')[0];

    if (!htmlElement) {
        return;
    }

    if (shouldLock) {
        htmlElement.classList.add('lock-scroll');
    } else {
        htmlElement.classList.remove('lock-scroll');
    }
}

function getScriptSelector(src) {
    return [src, src.replace(anyHttpRegexp, '')].map(script => `script[src='${script}']`).join(',');
}

export function isScriptLoaded(src) {
    return Boolean(document.querySelector(getScriptSelector(src)));
}

export function removeScript(src) {
    const scriptElement = document.querySelector(getScriptSelector(src));
    if (scriptElement) {
        scriptElement.remove();
    }
}

export function addScript(element, enableCookieSettings, enableCookie, force = false, parent = 'head') {
    if (!document) {
        return;
    }

    if (element.src && isScriptLoaded(element.src)) {
        if (force) {
            removeScript(element.src);
        } else {
            return;
        }
    }

    const script = document.createElement('script');

    Object.keys(element).forEach(function(prop) {
        if (scriptTagDataAttrRegexp.test(prop) && element[prop]) {
            script.setAttribute(prop, element[prop]);
        }
        if (scriptTagAttrsRegexp.test(prop) && element[prop]) {
            script[prop] = element[prop];
        }
    });

    script.type = !enableCookieSettings || enableCookie ? defaultScriptType : element.type;

    document.getElementsByTagName(parent)[0].appendChild(script);
}

export function initSaScript(saSite, enableCookieSettings, enablePerformanceCookie) {
    window.sa_interval = 5000;
    window.saLoadScript = loadSaScript;
    if (typeof window.shopper_first === 'undefined') {
        loadSaScript(rotatingWidget(saSite), enableCookieSettings, enablePerformanceCookie);
    } else {
        window.initsaJQWidget3();
    }
    window.shopper_first = true;
}

export function loadSaScript(src, enableCookieSettings, enablePerformanceCookie) {
    addScript(
        {
            src,
            type: cookieScriptTypes.performance
        },
        enableCookieSettings,
        enablePerformanceCookie
    );
}

export function reloadPage() {
    if (document) {
        document.location.reload();
    }
}

export function saveFileToClientDevice(name, type, data, isBase64 = false) {
    if (document) {
        const a = document.createElement('a');
        a.download = name;

        if (isBase64) {
            a.href = `data:${type};base64,${data}`;
        } else {
            const file = new Blob([data], {type});
            a.href = URL.createObjectURL(file);
        }

        a.click();
    }
}

export function downloadImage(imageUrl) {
    if (imageUrl) {
        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = imageUrl;
        a.click();
    }
}

export function loadFilesFromClientDevice(callback, multiple = false, accept) {
    if (document) {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = multiple;
        input.accept = accept;
        input.onchange = () => callback(Array.from(input.files));
        input.click();
    }
}

export function addValueToClipboard(value) {
    return new Promise((resolve, reject) => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(value).then(() => resolve());
            return;
        } else if (document) {
            addValueToClipboardForOldBrowser(value);
            resolve();
            return;
        }

        reject();
    });
}

function addValueToClipboardForOldBrowser(value) {
    const element = document.querySelector('body');
    const storage = document.createElement('textarea');
    storage.classList.add('clipboard-field');

    try {
        storage.value = value;
        element.appendChild(storage);
        storage.select();
        document.execCommand('copy');
    } finally {
        element.removeChild(storage);
    }
}

export const createMarkup = text => ({__html: text ? marked(text) : ''});
