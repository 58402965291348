/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';

const defaultState = {
    name: ''
};

const SET_NAME = (state, action) => {
    const {name} = action;
    if (!name) {
        return state;
    }

    return {...state, name};
};

const REPLACE_NAME_CONFIG_EMAILS = state => replaceEmails(state);

const REPLACE_NAME_CONFIG_PG_LINKS = state => convertHyperlinkToText(state);

const handlers = {};
handlers[actionTypes.SET_NAME] = SET_NAME;
handlers[actionTypes.REPLACE_NAME_CONFIG_EMAILS] = REPLACE_NAME_CONFIG_EMAILS;
handlers[actionTypes.REPLACE_NAME_CONFIG_PG_LINKS] = REPLACE_NAME_CONFIG_PG_LINKS;
export default createReducer(defaultState, handlers);
