import _ from 'lodash';

function deepMap(obj, iterator, context) {
    return _.transform(obj, function(result, val, key) {
        result[key] = _.isObject(val) ? deepMap(val, iterator, context) : iterator.call(context, val, key, obj);
    });
}

_.mixin({
    deepMap: deepMap
});
