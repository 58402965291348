import * as actionTypes from '../actionTypes';

export function setServerRoot(serverRoot) {
    return {
        type: actionTypes.SET_SERVER_ROOT,
        serverRoot
    };
}

export function setPublicServerRoot(publicServerRoot) {
    return {
        type: actionTypes.SET_PUBLIC_SERVER_ROOT,
        publicServerRoot
    };
}

export function setShopperApprovedData(saSite, saToken) {
    return {
        type: actionTypes.SET_SHOPPER_APPROVED_DATA,
        saToken,
        saSite
    };
}

export function setPartnerConfig(wlConfig) {
    return {
        type: actionTypes.SET_PARTNER_CONFIG,
        wlConfig
    };
}

export function setApiShopperApprovedToken(token) {
    return {
        type: actionTypes.SET_API_SA_TOKEN,
        token
    };
}

export function setEnableCookieSettings(enableCookieSettings) {
    return {
        type: actionTypes.SET_ENABLE_COOKIE_SETTINGS,
        enableCookieSettings
    };
}

export function setConfig(configState) {
    const {
        gaOptimizeContainerId,
        preventCrawlers,
        buildVersion,
        wlKlaviyoPublicKey,
        klaviyoPublicKey,
        useKlaviyo,
        wlGoogleTagManagerId,
        pgGoogleTagManagerId,
        wlGa4MeasurementId,
        ga4MeasurementId,
        wlGaMeasurementId,
        gaMeasurementId,
        disableGA,
        publicServerRoot,
        authCookieName,
        cookieSettingsCookieName,
        adminAuthCookieName,
        saSite,
        saToken,
        apiSaToken,
        enableCookieSettings,
        rootUri,
        pgfbpixelid,
        wlLiveChatId,
        pgPjPubKey,
        pgPjPubUrl,
        pgAudioeyeSiteHash
    } = configState;

    return {
        type: actionTypes.SET_CONFIG,
        state: {
            gaOptimizeContainerId,
            preventCrawlers,
            buildVersion,
            wlKlaviyoPublicKey,
            klaviyoPublicKey,
            useKlaviyo,
            wlGoogleTagManagerId,
            pgGoogleTagManagerId,
            wlGa4MeasurementId,
            ga4MeasurementId,
            wlGaMeasurementId,
            gaMeasurementId,
            disableGA,
            publicServerRoot,
            authCookieName,
            cookieSettingsCookieName,
            adminAuthCookieName,
            saSite,
            saToken,
            apiSaToken,
            enableCookieSettings,
            rootUri,
            pgfbpixelid,
            wlLiveChatId,
            pgPjPubKey,
            pgPjPubUrl,
            pgAudioeyeSiteHash
        }
    };
}
