import {httpsProtocol, protoDelimiter, urlQueryDelimiter} from '../constants/common';
import {anyHttpRegexp, endsWithSlashRegexp} from '../constants/regex';

export const getAbsoluteUrl = (domain, query) => {
    let url = '';

    if (domain) {
        url += `${!domain.includes(protoDelimiter) ? protoDelimiter : ''}${domain}`;
    }

    if (query) {
        url += `${!query.startsWith(urlQueryDelimiter) ? urlQueryDelimiter : ''}${query}`;
    }

    return url;
};

export const getUrl = (domain, query) => {
    const sanitizedDomain = domain.replace(endsWithSlashRegexp, '');

    return `${!anyHttpRegexp.test(sanitizedDomain) ? `${httpsProtocol}:` : ''}${getAbsoluteUrl(sanitizedDomain, query)}`;
};
