import {isEmpty} from 'lodash';
import {defaultAddress1, defaultAddressLine2, defaultCity, defaultState, defaultZip, ReorderDirection} from '../constants/common';

export function reorderItems(items, index, reorderDirection) {
    const isUp = reorderDirection === ReorderDirection.Up;
    const newItems = items
        .slice(0, isUp ? index - 1 : index)
        .concat(items[isUp ? index : index + 1], items[isUp ? index - 1 : index], items.slice(isUp ? index + 1 : index + 2));
    return newItems.map((item, i) => ({...item, display_order: i}));
}

export function sortByIdDesc(a, b) {
    return b.id - a.id;
}

export function clickPhoneNumber(partnerPhone = null) {
    const tel = document.createElement('a');
    const number = document.getElementsByClassName('site-phone')[0];

    const phoneText = partnerPhone || number.innerText;

    tel.href = `tel:${phoneText.replace(/\D/g, '')}`;
    tel.click();
}

export function parseStringToBoolean(value) {
    return value === 'true';
}

export function getFullAddress(partnerData) {
    if (!isEmpty(partnerData)) {
        const address2 = partnerData.address2 ? `, ${partnerData.address2}` : '';
        return `${partnerData.address1}${address2}, ${partnerData.city}, ${partnerData.state} ${partnerData.zip}`;
    }

    return `${defaultAddress1}, ${defaultAddressLine2}, ${defaultCity}, ${defaultState} ${defaultZip}`;
}

export function openLiveChat() {
    const chatWidget = document.getElementById('chat-widget-minimized');

    if (!chatWidget) {
        return;
    }

    const chatButtons = chatWidget.contentDocument.getElementsByTagName('button');
    const [chatButton] = chatButtons;

    if (chatButton) {
        chatButton.click();
    }
}

export function openChat(e) {
    e.preventDefault();
    if (!document) {
        return;
    }
    openLiveChat();
}

export const roundTo2DecimalPlaces = number => Number(number).toFixed(2);

export const restoreHiddenEmails = (str = '') =>
    str.replace(/\{\{(.*?)\}\}/g, function(x) {
        const cipherText = x.replace(/\{\{|\}\}/g, '');
        const buffer = Buffer.from(cipherText, 'base64');

        return buffer.toString('ascii');
    });
