/* @flow */
import * as actionTypes from '../actionTypes';
import {defaultTimeZone} from '../constants/workTime';
import createReducer from '../lib/createReducer';
import {cloneDeep, isEmpty, sortBy} from 'lodash';

const defaultFeaturedCategoriesCarousel = {
    id: 0,
    title: '',
    description: '',
    categories: []
};

const defaultState = {
    bulkEditObject: {},
    categories: {categories: [], total: 0},
    masterCategories: {categories: [], total: 0},
    subCategories: {categories: [], total: 0},
    superCategories: {categories: [], total: 0},
    admin_employee_id: null,
    coupons: [],
    coupon: {},
    emails: [],
    employee: {},
    employees: [],
    ssoConfiguration: null,
    customPaymentFlow: null,
    customPaymentFlowField: null,
    mega_menu: {
        master_categories_with_cats: [],
        master_categories_without_cats: [],
        super_categories: []
    },
    mobile_menu: {
        business: [],
        promotional: []
    },
    theme_menu: {},
    fonts: [],
    object: {},
    objectType: undefined,
    orders: [],
    reorders: [],
    ccInformation: [],
    permission: {},
    product_feature: {},
    product_features: [],
    products: [],
    partners: [],
    redirect: {},
    redirects: [],
    searchableColors: [],
    sitewidePriceMultiplier: 0,
    sitewidePriceMultiplierNotes: null,
    shippingPriceMultiplier: 0,
    special: {},
    specials: [],
    synonyms: [],
    total: 0,
    vendor: {},
    vendorPriceMultipliers: [],
    vendor_report: [],
    vendors: [],
    social_links: [],
    redirect_object_type: null,
    redirect_object_id: null,
    home_page_carousel: [],
    docs: [],
    workTime: {
        days: {
            start: null,
            end: null
        },
        times: {
            start: null,
            end: null
        },
        wl_days: {
            start: null,
            end: null
        },
        wl_times: {
            start: null,
            end: null
        },
        time_zone: defaultTimeZone,
        wl_time_zone: defaultTimeZone
    },
    featuredCategoriesCarousel: defaultFeaturedCategoriesCarousel
};

function COPY_ADMIN_COUPON(state) {
    const coupon = {...state.coupon, id: undefined, isCopy: true};
    return {...state, coupon};
}

function COPY_ADMIN_CAROUSEL(state) {
    const carousel = {...state.carousel, id: undefined, isCopy: true};
    return {...state, carousel};
}

function COPY_ADMIN_FEATURE(state) {
    const product_feature = {...state.product_feature, id: undefined, isCopy: true};
    return {...state, product_feature};
}

function COPY_ADMIN_OBJECT(state) {
    let object = cloneDeep(state.object);
    object.parent_object = state.object;
    object.id = undefined;
    object.isCopy = true;
    return {...state, object};
}

function COPY_ADMIN_REDIRECT(state) {
    const redirect = {...state.redirect, id: undefined, isCopy: true};
    return {...state, redirect};
}

function COPY_ADMIN_SPECIAL(state) {
    const special = {...state.special, id: undefined, isCopy: true};
    return {...state, special};
}

function COPY_ADMIN_VENDOR(state) {
    const vendor = {...state.vendor, id: undefined, isCopy: true};
    return {...state, vendor};
}

function SET_ADMIN_BULK_EDIT(state, action) {
    const {bulkEditObject} = action;
    return {...state, bulkEditObject};
}

function SET_ADMIN_CATEGORIES(state, action) {
    const {superCategories, masterCategories, categories, subCategories} = action.categories;
    return {...state, superCategories, masterCategories, categories, subCategories};
}

function SET_ADMIN_FONTS(state, action) {
    const {fonts} = action;
    return {...state, fonts};
}

function SET_ADMIN_SEARCHABLE_COLORS(state, action) {
    const {searchableColors} = action;
    return {...state, searchableColors};
}

function SET_ADMIN_COUPON(state, action) {
    const {coupon} = action;
    if (!coupon) {
        return state;
    }

    return {...state, coupon};
}

function SET_ADMIN_COUPONS(state, action) {
    const {coupons, total} = action;
    if (isEmpty(coupons)) {
        return state;
    }

    return {...state, coupons, total};
}

function SET_ADMIN_EMPLOYEE(state, action) {
    const {employee} = action;
    if (!employee) {
        return state;
    }

    return {...state, employee};
}

function SET_ADMIN_EMPLOYEES(state, action) {
    const {employees, total} = action;
    if (isEmpty(employees)) {
        return state;
    }
    return {...state, employees, total};
}

function SET_ADMIN_ATTRIBUTES(state, action) {
    const {admin_employee_id, permission} = action;
    return {...state, admin_employee_id, permission};
}

function SET_ADMIN_MEGA_MENU(state, action) {
    const {mega_menu} = action;
    return {...state, mega_menu};
}

function SET_ADMIN_THEME_MENU(state, action) {
    const {theme_menu} = action;
    return {...state, theme_menu};
}

function SET_MOBILE_MENU(state, action) {
    const {business, promotional} = action;
    return {...state, mobile_menu: {business, promotional}};
}

function SET_ADMIN_OBJECT(state, action) {
    let {object, objectType} = action;
    if (!object) {
        object = {};
    }
    if (!objectType) {
        return state;
    }
    return {...state, object, objectType};
}

function SET_ADMIN_ORDERS(state, action) {
    const {orders, total} = action;
    if (!orders) {
        return {...state, orders: []};
    }
    return {...state, orders, total};
}

function SET_ADMIN_REORDERS(state, action) {
    const {reorders, total} = action;
    if (!reorders) {
        return {...state, reorders: []};
    }
    return {...state, reorders, total};
}

function SET_ADMIN_CC_INFORMATION(state, action) {
    const {ccInformation, total} = action;
    if (!ccInformation) {
        return {...state, ccInformation: []};
    }
    return {...state, ccInformation, total};
}

function SET_ADMIN_PRODUCT_FEATURE(state, action) {
    const {product_feature} = action;
    if (!product_feature) {
        return state;
    }

    return {...state, product_feature};
}

function SET_ADMIN_PRODUCT_FEATURES(state, action) {
    const {product_features, total} = action;
    if (isEmpty(product_features)) {
        return state;
    }

    return {...state, product_features, total};
}

function SET_ADMIN_PRODUCTS(state, action) {
    let {products, total} = action;
    if (!products) {
        return {...state, products: [], total: 0};
    }
    if (!total) {
        total = products.length;
    }
    return {...state, products, total};
}

function SET_ADMIN_REDIRECT(state, action) {
    const {redirect} = action;
    if (!redirect) {
        return state;
    }
    return {...state, redirect: {...state.redirect, ...redirect}};
}

function SET_ADMIN_REDIRECTS(state, action) {
    let {redirects, total} = action;
    if (!redirects) {
        return state;
    }
    if (!total) {
        return state;
    }

    return {...state, redirects, total};
}

function SET_ADMIN_SITEWIDE_PRICE_MULTIPLIER(state, action) {
    let {sitewidePriceMultiplier, vendorPriceMultipliers, sitewidePriceMultiplierNotes, shippingPriceMultiplier} = action;
    return {
        ...state,
        sitewidePriceMultiplier,
        vendorPriceMultipliers,
        sitewidePriceMultiplierNotes,
        shippingPriceMultiplier
    };
}

function SET_ADMIN_SPECIAL(state, action) {
    let {special} = action;
    if (!special) {
        return state;
    }
    return {...state, special};
}

function SET_ADMIN_SPECIALS(state, action) {
    let {specials, total} = action;
    if (!specials || !total) {
        return state;
    }
    return {...state, specials, total};
}

function SET_ADMIN_VENDOR(state, action) {
    const {vendor} = action;
    if (!vendor) {
        return state;
    }
    return {...state, vendor};
}

function SET_ADMIN_SYNONYM_LIST(state, action) {
    let {synonyms, total} = action;
    if (!synonyms) {
        return state;
    }
    if (!total) {
        total = synonyms.length;
    }
    return {...state, synonyms, total};
}

function SET_ADMIN_VENDOR_LIST(state, action) {
    let {vendors, total} = action;
    if (!vendors) {
        return state;
    }
    if (!total) {
        total = vendors.length;
    }
    return {...state, vendors, total};
}

function SET_ADMIN_PARTNERS(state, {partners = [], total = 0}) {
    return {...state, partners, total: total || partners.length};
}

function PREFILL_ADMIN_REDIRECT(state, action) {
    const {redirect, objectType, object_id} = action;
    if (!redirect) {
        return state;
    }

    return {...state, redirect, redirect_object_type: objectType, redirect_object_id: object_id};
}

function SET_VENDOR_REPORT(state, action) {
    const {vendor_report} = action;
    if (!vendor_report) {
        return state;
    }

    return {...state, vendor_report};
}

function SET_HOME_PAGE_BANNERS(state, action) {
    const {carousel} = action;

    return {
        ...state,
        home_page_carousel: carousel
    };
}

function SET_EMAILS(state, action) {
    const {emails} = action;
    return {
        ...state,
        emails
    };
}

const SET_DOCS = (state, action) => {
    const {docs} = action;
    return {...state, docs};
};

function SET_ADMIN_SSO_CONFIGURATION(state, action) {
    const {ssoConfiguration} = action;
    if (!ssoConfiguration) {
        return state;
    }

    return {...state, ssoConfiguration};
}

function SET_ADMIN_CUSTOM_PAYMENT_FLOW(state, action) {
    const {customPaymentFlow} = action;
    if (!customPaymentFlow) {
        return state;
    }

    return {...state, customPaymentFlow};
}

function SET_ADMIN_CUSTOM_PAYMENT_FLOW_FIELD(state, action) {
    const {customPaymentFlowField} = action;
    if (!customPaymentFlowField) {
        return state;
    }

    return {...state, customPaymentFlowField};
}

function SET_ADMIN_WORK_TIME(state, action) {
    const {workTime} = action;
    if (!workTime) {
        return state;
    }

    return {...state, workTime};
}

function SET_SOCIAL_LINKS(state, action) {
    const {social_links} = action;
    return {...state, social_links: sortBy(social_links, 'order_position')};
}

function SET_FEATURED_CATEGORIES_CAROUSEL(state, action) {
    const {carousel} = action;
    return {...state, featuredCategoriesCarousel: {...defaultFeaturedCategoriesCarousel, ...carousel}};
}

const handlers = {};
handlers[actionTypes.COPY_ADMIN_CAROUSEL] = COPY_ADMIN_CAROUSEL;
handlers[actionTypes.COPY_ADMIN_COUPON] = COPY_ADMIN_COUPON;
handlers[actionTypes.COPY_ADMIN_FEATURE] = COPY_ADMIN_FEATURE;
handlers[actionTypes.COPY_ADMIN_OBJECT] = COPY_ADMIN_OBJECT;
handlers[actionTypes.COPY_ADMIN_REDIRECT] = COPY_ADMIN_REDIRECT;
handlers[actionTypes.COPY_ADMIN_SPECIAL] = COPY_ADMIN_SPECIAL;
handlers[actionTypes.COPY_ADMIN_VENDOR] = COPY_ADMIN_VENDOR;
handlers[actionTypes.PREFILL_ADMIN_REDIRECT] = PREFILL_ADMIN_REDIRECT;
handlers[actionTypes.SET_ADMIN_ATTRIBUTES] = SET_ADMIN_ATTRIBUTES;
handlers[actionTypes.SET_ADMIN_BULK_EDIT] = SET_ADMIN_BULK_EDIT;
handlers[actionTypes.SET_ADMIN_CATEGORIES] = SET_ADMIN_CATEGORIES;
handlers[actionTypes.SET_ADMIN_COUPON] = SET_ADMIN_COUPON;
handlers[actionTypes.SET_ADMIN_COUPONS] = SET_ADMIN_COUPONS;
handlers[actionTypes.SET_ADMIN_EMPLOYEE] = SET_ADMIN_EMPLOYEE;
handlers[actionTypes.SET_ADMIN_EMPLOYEES] = SET_ADMIN_EMPLOYEES;
handlers[actionTypes.SET_ADMIN_MEGA_MENU] = SET_ADMIN_MEGA_MENU;
handlers[actionTypes.SET_ADMIN_THEME_MENU] = SET_ADMIN_THEME_MENU;
handlers[actionTypes.SET_MOBILE_MENU] = SET_MOBILE_MENU;
handlers[actionTypes.SET_ADMIN_FONTS] = SET_ADMIN_FONTS;
handlers[actionTypes.SET_ADMIN_OBJECT] = SET_ADMIN_OBJECT;
handlers[actionTypes.SET_ADMIN_ORDERS] = SET_ADMIN_ORDERS;
handlers[actionTypes.SET_ADMIN_REORDERS] = SET_ADMIN_REORDERS;
handlers[actionTypes.SET_ADMIN_PRODUCT_FEATURE] = SET_ADMIN_PRODUCT_FEATURE;
handlers[actionTypes.SET_ADMIN_PRODUCT_FEATURES] = SET_ADMIN_PRODUCT_FEATURES;
handlers[actionTypes.SET_ADMIN_PRODUCTS] = SET_ADMIN_PRODUCTS;
handlers[actionTypes.SET_ADMIN_REDIRECT] = SET_ADMIN_REDIRECT;
handlers[actionTypes.SET_ADMIN_REDIRECTS] = SET_ADMIN_REDIRECTS;
handlers[actionTypes.SET_ADMIN_SEARCHABLE_COLORS] = SET_ADMIN_SEARCHABLE_COLORS;
handlers[actionTypes.SET_ADMIN_SITEWIDE_PRICE_MULTIPLIER] = SET_ADMIN_SITEWIDE_PRICE_MULTIPLIER;
handlers[actionTypes.SET_ADMIN_SPECIAL] = SET_ADMIN_SPECIAL;
handlers[actionTypes.SET_ADMIN_SPECIALS] = SET_ADMIN_SPECIALS;
handlers[actionTypes.SET_ADMIN_SYNONYM_LIST] = SET_ADMIN_SYNONYM_LIST;
handlers[actionTypes.SET_ADMIN_VENDOR] = SET_ADMIN_VENDOR;
handlers[actionTypes.SET_ADMIN_VENDOR_LIST] = SET_ADMIN_VENDOR_LIST;
handlers[actionTypes.SET_ADMIN_PARTNERS] = SET_ADMIN_PARTNERS;
handlers[actionTypes.SET_VENDOR_REPORT] = SET_VENDOR_REPORT;
handlers[actionTypes.SET_HOME_PAGE_BANNERS] = SET_HOME_PAGE_BANNERS;
handlers[actionTypes.SET_EMAILS] = SET_EMAILS;
handlers[actionTypes.SET_DOCS] = SET_DOCS;
handlers[actionTypes.SET_ADMIN_CC_INFORMATION] = SET_ADMIN_CC_INFORMATION;
handlers[actionTypes.SET_ADMIN_SSO_CONFIGURATION] = SET_ADMIN_SSO_CONFIGURATION;
handlers[actionTypes.SET_ADMIN_CUSTOM_PAYMENT_FLOW] = SET_ADMIN_CUSTOM_PAYMENT_FLOW;
handlers[actionTypes.SET_ADMIN_CUSTOM_PAYMENT_FLOW_FIELD] = SET_ADMIN_CUSTOM_PAYMENT_FLOW_FIELD;
handlers[actionTypes.SET_ADMIN_WORK_TIME] = SET_ADMIN_WORK_TIME;
handlers[actionTypes.SET_SOCIAL_LINKS] = SET_SOCIAL_LINKS;
handlers[actionTypes.SET_FEATURED_CATEGORIES_CAROUSEL] = SET_FEATURED_CATEGORIES_CAROUSEL;
export default createReducer(defaultState, handlers);
