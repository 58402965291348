import {cookieScriptTypes} from '../constants/cookies';
import {productInformationTabs} from '../constants/product';
import {
    answerBaseConversionTrackerSrc,
    callToActionSrc,
    guardBannerAnimationId,
    productReviewsCount,
    qaWidgetSrc,
    saDateFormat,
    saHost
} from '../constants/shopperApproved';
import {getProductUrl} from '../lib/product';
import {getUrl} from '../lib/url';
import {addScript} from './browser';

export const saProductReview = (sku, saSite, enableCookieSettings, performance) => {
    window.sa_products_count = productReviewsCount;
    window.sa_date_format = saDateFormat;
    window.sa_product = sku;
    addProductReviewScript(getReviewProductSrc(window.sa_product, saSite), enableCookieSettings, performance);
};

export function setupThankYouScriptData(items) {
    window.sa_products = {};
    items.forEach(item => {
        window.sa_products[item.public_sku] = item.name;
    });
}

export const qaCategoryWidget = (category, serverRoot, enableCookieSettings, performance) => {
    const script = {
        src: qaWidgetSrc,
        defer: true
    };

    script['data-external-product-category-id'] = category.id;
    script['data-product-category-title'] = category.name;
    script['data-product-category-url'] = `${window.location.protocol}//${window.location.host}${category.url_name}`;
    script['data-insert-after'] = '.category-long-description-wrapper';
    if (category.primary_image) {
        script['data-product-category-image'] = `${window.location.protocol}//${window.location.host}${category.primary_image.url_path}`;
    }
    addScript(script, enableCookieSettings, performance, true);
};

export const qaWidget = (product, serverRoot, enableCookieSettings, performance) => {
    const price = product.base_prices[0];
    const externalId = product.id;
    const url = getUrl(serverRoot, getProductUrl(product.url_name, product.id));
    const imageUrl = getUrl(serverRoot, product.primary_image.url_path);
    const associationProductCategory = product.subcategories.map(category => category.id).join(',');

    const ctaWidget = document.getElementById('ab-tiny-widget');
    if (ctaWidget) {
        ctaWidget.remove();
    }

    for (const widget of document.getElementsByClassName('answerbase-widget')) {
        widget.remove();
    }

    // QA widget
    const featuredWidgetScript = {
        src: qaWidgetSrc,
        defer: true
    };
    featuredWidgetScript['data-product-external-id'] = externalId;
    featuredWidgetScript['data-product-sku'] = product.public_sku;
    featuredWidgetScript['data-product-title'] = product.name;
    featuredWidgetScript['data-product-price'] = price;
    featuredWidgetScript['data-product-url'] = url;
    featuredWidgetScript['data-product-description'] = product.short_desc;
    featuredWidgetScript['data-product-image-url'] = imageUrl;
    featuredWidgetScript['data-product-association-product-category'] = associationProductCategory;
    featuredWidgetScript['data-insert-after'] = '.sa-reviews';
    featuredWidgetScript['data-max-width'] = '1320px';
    featuredWidgetScript['data-on-page-search-elements'] = `.product-description, Product Description;
        .product-description-short, Product Description;
        .qa-widget-search-content.product-details, ${productInformationTabs.details};
        .qa-widget-search-content.proofing-artwork, ${productInformationTabs.proofingArtwork};
        .qa-widget-search-content.ordering-information, ${productInformationTabs.orderingInformation}`;
    addScript(featuredWidgetScript, enableCookieSettings, performance, true);

    // call to action widget
    const ctaWidgetScript = {
        src: callToActionSrc,
        defer: true
    };
    ctaWidgetScript['data-insert-after'] = '.call-to-action';
    addScript(ctaWidgetScript, enableCookieSettings, performance, true);
};

export function getReviewProductSrc(sku, saSite) {
    return `${saHost}/widgets/${saSite}/product/${sku}/product-widget/25bLyFPjtW9M.js`;
}

function addProductReviewScript(src, enableCookieSettings, performance) {
    if (!window.shopperapproved) {
        window.shopperapproved = function() {
            (window.shopperapproved.arg = window.shopperapproved.arg || []).push(arguments);
        };
    }

    const productJustStars = document.getElementById('product_just_stars');
    if (productJustStars) {
        productJustStars.innerHTML = '';
    }
    const reviewHeader = document.getElementById('review_header');
    if (reviewHeader) {
        reviewHeader.innerHTML = '';
    }
    const productPage = document.getElementById('product_page');
    if (productPage) {
        productPage.innerHTML = '';
    }

    addScript(
        {
            src,
            async: true,
            type: cookieScriptTypes.performance
        },
        enableCookieSettings,
        performance,
        true
    );
}

/* eslint-disable no-undef */
export function initGuardBanners() {
    // tg_script_bottom_left, tg_script_bottom_right, tg_load_animations(), tg_load_animations(), tgCheckSealRight()
    // can't be accessed in the window scope and should call directly.
    const tgDiv = document.createElement('div');
    tgDiv.innerHTML = tg_script_bottom_left;
    document.body.appendChild(tgDiv);
    tg_load_animations(guardBannerAnimationId, 'bottom_left');

    if (document.getElementsByClassName('cart-banner')) {
        const tgdivCart = document.createElement('div');
        tgdivCart.innerHTML = tg_script_bottom_right;
        document.body.appendChild(tgdivCart);
        tg_load_animations(guardBannerAnimationId, 'bottom_right');
        tgCheckSealRight();
    }
}

export function onContextMenu() {
    const currentDate = new Date();
    alert('Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard ' + currentDate.getFullYear() + '.');
    return false;
}

export function addAnswerBaseConversionTrackerScript(enableCookieSettings, marketing, total, email, skuStr, idStr, priceStr) {
    const answerBaseConversionTrackerScript = {
        src: answerBaseConversionTrackerSrc,
        defer: true
    };
    answerBaseConversionTrackerScript['data-sale_total'] = total;
    answerBaseConversionTrackerScript['data-email'] = email;
    answerBaseConversionTrackerScript['data-sku'] = skuStr;
    answerBaseConversionTrackerScript['data-productId'] = idStr;
    answerBaseConversionTrackerScript['data-price'] = priceStr;

    addScript(answerBaseConversionTrackerScript, enableCookieSettings, marketing, true);
}
