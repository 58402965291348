import {statusActive} from '../constants/product';
import {roundTo2DecimalPlaces, sortByIdDesc} from './common';
import {getCategories, getFullProductUrl} from './product';

export function getAbleToCheckout(item) {
    return item.product.active === statusActive && item.isAbleToCheckout;
}

export function getAbleToCheckoutItems(cart) {
    return cart.filter(item => getAbleToCheckout(item)).sort(sortByIdDesc);
}

export function getNotAbleToCheckoutItems(cart) {
    return cart.filter(item => !getAbleToCheckout(item)).sort(sortByIdDesc);
}

export function checkIfCartContainsNonActiveItems(cart) {
    return cart.some(item => item.product.active !== statusActive);
}

export function checkIfCartContainsActiveItems(cart) {
    return cart.some(item => item.product.active === statusActive);
}

export function calculateItemsTotal(previous, current) {
    if (current.product.active === statusActive) {
        let shipping_fee = current.estimated_shipping;
        shipping_fee = !shipping_fee ? 0 : Number(shipping_fee);

        return previous + Number(current.total) + shipping_fee;
    }

    return previous;
}

export function convertCartItemToKlaviyoCartItem(item, urlRoot) {
    if (!item) {
        return {};
    }

    return {
        ProductID: item.product.id.toString(),
        SKU: item.product.public_sku,
        ProductName: item.product.name,
        Quantity: item.quantity,
        ItemPrice: roundTo2DecimalPlaces(item.subtotal / item.quantity),
        RowTotal: roundTo2DecimalPlaces(item.subtotal),
        ProductURL: getFullProductUrl(urlRoot, item.product),
        ImageURL: `${urlRoot}${item.product.primary_image.url_path}`,
        ProductCategories: getCategories(item.product)
    };
}
