import _ from 'lodash';
import {parse} from 'node-html-parser';
import {printglobeUrl} from '../constants/common';

const convertLinksToPlainText = value => {
    const html = parse(value);
    const hrefs = html.querySelectorAll('a');

    if (hrefs.length === 0) {
        return value;
    }

    for (const el of hrefs) {
        if (el.getAttribute('href').includes('blog') || el.getAttribute('href').includes(printglobeUrl)) {
            el.replaceWith(el.text);
        }
    }

    return html.innerHTML;
};

export const convertHyperlinkToText = state => _.deepMap(state, convertLinksToPlainText);
