// The following line is a polyfill for require.ensure on the server.
if (typeof require.ensure !== 'function') {
    require.ensure = function(d, c) {
        c(require);
    };
}

import React from 'react';
import {Route} from 'react-router';
import {
    ccSubmissionFormPageRoute,
    criticalShippingOrdersPageRoute,
    shopByOccasionPageRouter,
    swagStorePageRoute,
    wlCustomEmailPageRouter,
    wlExcludedTemplatesPageRouter
} from './constants/routers';
import {customPaymentFlowFieldPageRouter, customPaymentFlowPageRouter, ssoConfigurationPageRouter} from './constants/routers';

export default function createRoutes(onEnter, isPartner) {
    return (
        <Route name="App" getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/App').default), 'App')}>
            <Route
                path={criticalShippingOrdersPageRoute}
                name="CriticalShippingOrdersPage"
                getComponent={(location, cb) =>
                    require.ensure([], require => cb(null, require('./components/storefront/CriticalShippingOrdersPage').default), 'CriticalShippingOrdersPage')
                }
                onEnter={onEnter}
            />
            <Route
                path={ccSubmissionFormPageRoute}
                name="SubmissionFormPage"
                getComponent={(location, cb) =>
                    require.ensure([], require => cb(null, require('./components/storefront/SubmissionFormPage').default), 'SubmissionFormPage')
                }
                onEnter={onEnter}
            />
            <Route
                path="/admin/login"
                name="AdminLoginPage"
                getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/LoginPage').default), 'AdminLoginPage')}
                onEnter={onEnter}
            />
            <Route
                path="/product-images/:productName/:id"
                name="GreenTextImages"
                getComponent={(location, cb) =>
                    require.ensure([], require => cb(null, require('components/storefront/GreenTextImages').default), 'GreenTextImages')
                }
                onEnter={onEnter}
            />
            <Route name="Admin" getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/Admin').default), 'Admin')}>
                <Route
                    path="/admin/change"
                    name="AdminChangePassword"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/AdminChangePassword').default), 'AdminChangePassword')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin"
                    name="OrderListPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/OrderListPage').default), 'OrderListPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/search"
                    name="AdminPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/AdminPage').default), 'AdminPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/shipping"
                    name="ShippingCalculatorPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ShippingCalculatorPage').default), 'ShippingCalculatorPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/managehome"
                    name="ManageHomePage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ManageHomePage').default), 'ManageHomePage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/menus"
                    name="MenuOrderingPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/MenuOrderingPage').default), 'MenuOrderingPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/emails"
                    name="EmailListPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/EmailListPage').default), 'EmailListPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/reports"
                    name="ReportsPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/ReportsPage').default), 'ReportsPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/vendors"
                    name="VendorListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/VendorListPage').default), 'VendorListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/orders"
                    name="OrderListPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/OrderListPage').default), 'OrderListPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/reorders"
                    name="ReorderListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ReorderListPage').default), 'ReorderListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/ccInformation"
                    name="CcInformationPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/CcInformationPage').default), 'CcInformationPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/categories"
                    name="AdminCategoryListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/CategoryListPage').default), 'AdminCategoryListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/products"
                    name="AdminProductListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ProductListPage').default), 'AdminProductListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/redirects"
                    name="AdminRedirectsPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/AdminRedirectsPage').default), 'AdminRedirectsPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/social_links"
                    name="SocialLinksPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/SocialLinksPage').default), 'SocialLinksPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/synonyms"
                    name="SynonymPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/SynonymPage').default), 'SynonymPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/redirect/:id"
                    name="RedirectPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/RedirectPage').default), 'RedirectPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/features"
                    name="ProductFeaturesListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ProductFeaturesListPage').default), 'ProductFeaturesListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/feature/:id"
                    name="ProductFeaturePage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ProductFeaturePage').default), 'ProductFeaturePage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/specials"
                    name="SpecialListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/SpecialListPage').default), 'SpecialListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/employees"
                    name="EmployeeListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/EmployeeListPage').default), 'EmployeeListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/employee/:id"
                    name="EmployeePage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/EmployeePage').default), 'EmployeePage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/coupons"
                    name="CouponListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/CouponListPage').default), 'CouponListPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/coupon/:id"
                    name="CouponPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/CouponPage').default), 'CouponPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/colorsAndFonts"
                    name="ColorsAndFontsPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/ColorsAndFontsPage').default), 'ColorsAndFontsPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/sitewidePriceMultiplier"
                    name="SitewidePricingPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/SitewidePricingPage').default), 'SitewidePricingPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/bulk/vendor"
                    name="BulkVendorImportPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/BulkVendorImportPage').default), 'BulkVendorImportPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/bulk/products"
                    name="BulkProductEditPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/BulkProductEditPage').default), 'BulkProductEditPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/templates"
                    name="AdminTemplatesPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/AdminTemplatesPage').default), 'AdminTemplatesPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/templates/partners"
                    name="PartnerTemplatesPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/PartnerTemplatesPage').default), 'PartnerTemplatesPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${wlCustomEmailPageRouter}:partnerId/:id`}
                    name="WlCustomEmailPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/WlCustomEmailPage').default), 'WlCustomEmailPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${shopByOccasionPageRouter}/:id`}
                    name="ShopByOccasionPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/shopByOccasion/ShopByOccasionPage').default), 'ShopByOccasionPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={shopByOccasionPageRouter}
                    name="ShopByOccasionListPage"
                    getComponent={(location, cb) =>
                        require.ensure(
                            [],
                            require => cb(null, require('components/admin/wl/shopByOccasion/ShopByOccasionListPage').default),
                            'ShopByOccasionListPage'
                        )
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={wlExcludedTemplatesPageRouter}
                    name="ExcludedTemplatesPage"
                    getComponent={(location, cb) =>
                        require.ensure(
                            [],
                            require => cb(null, require('components/admin/wl/ExcludedTemplates/ExcludedTemplatesListPage').default),
                            'ExcludedTemplatesListPage'
                        )
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${wlExcludedTemplatesPageRouter}/:id`}
                    name="ExcludedTemplatesPage"
                    getComponent={(location, cb) =>
                        require.ensure(
                            [],
                            require => cb(null, require('components/admin/wl/ExcludedTemplates/ExcludedTemplatesPage').default),
                            'ExcludedTemplatesPage'
                        )
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${ssoConfigurationPageRouter}:partnerId/:id`}
                    name="SsoConfigurationPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/SsoConfigurationPage').default), 'SsoConfigurationPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${customPaymentFlowPageRouter}:partnerId/:id`}
                    name="CustomPaymentFlowPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/CustomPaymentFlowPage').default), 'CustomPaymentFlowPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path={`${customPaymentFlowFieldPageRouter}:flowId/:id`}
                    name="CustomPaymentFlowFieldPage"
                    getComponent={(location, cb) =>
                        require.ensure(
                            [],
                            require => cb(null, require('components/admin/wl/CustomPaymentFlowPage/CustomPaymentFlowFieldPage').default),
                            'CustomPaymentFlowFieldPage'
                        )
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/:objectType/:id"
                    name="AdminSwitch"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/admin/AdminSwitch').default), 'AdminSwitch')}
                    onEnter={onEnter}
                />
                <Route
                    path="/admin/partners"
                    name="AdminPartnerListPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/admin/wl/PartnerListPage').default), 'AdminPartnerListPage')
                    }
                    onEnter={onEnter}
                />
            </Route>
            <Route
                name="StoreFront"
                getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/StoreFront').default), 'StoreFront')}
            >
                {isPartner && (
                    <Route
                        path={swagStorePageRoute}
                        name="SwagStorePage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('./components/storefront/SwagStorePage').default), 'SwagStorePage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/"
                    name="HomePage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/HomePage').default), 'HomePage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/customize/:productName/:id"
                    name="CustomizePage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/CustomizePage').default), 'CustomizePage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/login"
                    name="LoginPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/LoginPage').default), 'LoginPage')}
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/register"
                        name="RegisterPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/RegisterPage').default), 'RegisterPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/account"
                    name="YourAccountPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/YourAccountPage').default), 'YourAccountPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/cart"
                    name="CartPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/CartPage').default), 'CartPage')}
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/coupons"
                        name="CouponsPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/CouponsPage').default), 'CouponsPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/checkout"
                    name="CheckoutPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/CheckoutPage').default), 'CheckoutPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/search"
                    name="SearchResultsPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/SearchResultsPage').default), 'SearchResultsPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/privacy"
                    name="PrivacyPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/PrivacyPage').default), 'PrivacyPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/privacy/ccpa"
                    name="PrivacyCaliforniaPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/PrivacyCaliforniaPage').default), 'PrivacyCaliforniaPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/faq"
                    name="FAQPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/FAQPage').default), 'FAQPage')}
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/reviews"
                        name="ReviewsPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/ReviewsPage').default), 'ReviewsPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/terms"
                    name="TermsPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/TermsPage').default), 'TermsPage')}
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/reseller"
                        name="ResellerProgramPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/ResellerProgramPage').default), 'ResellerProgramPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/purchase-orders"
                    name="PurchaseOrderPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/PurchaseOrderPage').default), 'PurchaseOrderPage')
                    }
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/volume-orders"
                        name="VolumeOrderPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/VolumeOrderPage').default), 'VolumeOrderPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/contact"
                    name="ContactUsPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/ContactUsPage').default), 'ContactUsPage')
                    }
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/credit-policy"
                        name="CreditPolicyPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/CreditPolicyPage').default), 'CreditPolicyPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/company-store"
                    name="CompanyStorePage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/CompanyStorePage').default), 'CompanyStorePage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/upload-artwork"
                    name="UploadArtworkPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/UploadArtworkPage').default), 'UploadArtworkPage')
                    }
                    onEnter={onEnter}
                />
                {!isPartner && (
                    <Route
                        path="/about-us"
                        name="AboutUsPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/AboutUsPage').default), 'AboutUsPage')
                        }
                        onEnter={onEnter}
                    />
                )}
                <Route
                    path="/quick-ship"
                    name="QuickTurnPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/QuickTurnPage').default), 'QuickTurnPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/hot-deals"
                    name="HotDealsPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/HotDealsPage').default), 'HotDealsPage')
                    }
                    onEnter={onEnter}
                />
                {!isPartner && [
                    <Route
                        key="PrintingPage"
                        path="/printing/:printing_page_url"
                        name="PrintingPage"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/PrintingPage').default), 'PrintingPage')
                        }
                        onEnter={onEnter}
                    />,
                    <Route
                        key="AccountManager"
                        path="/accountmanager"
                        name="AccountManager"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/JobsPage').default), 'AccountManager')
                        }
                        onEnter={onEnter}
                    />,
                    <Route
                        key="GraphicDesigner"
                        path="/graphicdesigner"
                        name="GraphicDesigner"
                        getComponent={(location, cb) =>
                            require.ensure([], require => cb(null, require('components/storefront/JobsPage').default), 'GraphicDesigner')
                        }
                        onEnter={onEnter}
                    />
                ]}
                <Route
                    path="/404"
                    name="NotFoundPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/NotFoundPage').default), 'NotFoundPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/500"
                    name="ErrorPage"
                    getComponent={(location, cb) => require.ensure([], require => cb(null, require('components/storefront/ErrorPage').default), 'ErrorPage')}
                    onEnter={onEnter}
                />
                <Route
                    path="/:productName/:id"
                    name="ProductPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/ProductPage').default), 'ProductPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="/:categoryName"
                    name="CategoryPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/CategoryPage').default), 'CategoryPage')
                    }
                    onEnter={onEnter}
                />
                <Route
                    path="*"
                    name="NotFoundPage"
                    getComponent={(location, cb) =>
                        require.ensure([], require => cb(null, require('components/storefront/NotFoundPage').default), 'NotFoundPage')
                    }
                    onEnter={onEnter}
                />
            </Route>
        </Route>
    );
}
