/* @flow */
export const ADD_PREV_AND_NEXT = 'ADD_PREV_AND_NEXT';
export const CHANGE_SHOP_BY_CATEGORY = 'CHANGE_SHOP_BY_CATEGORY';
export const CLEAR_LAST_CART = 'CLEAR_LAST_CART';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const COPY_ADMIN_CAROUSEL = 'COPY_ADMIN_CAROUSEL';
export const COPY_ADMIN_COUPON = 'COPY_ADMIN_COUPON';
export const COPY_ADMIN_FEATURE = 'COPY_ADMIN_FEATURE';
export const COPY_ADMIN_OBJECT = 'COPY_ADMIN_OBJECT';
export const COPY_ADMIN_REDIRECT = 'COPY_ADMIN_REDIRECT';
export const COPY_ADMIN_SPECIAL = 'COP_ADMIN_SPECIAL';
export const COPY_ADMIN_VENDOR = 'COPY_ADMIN_VENDOR';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_SAVED_ITEM = 'DELETE_SAVED_ITEM';
export const DISABLE_FOOTER_STRIP = 'DISABLE_FOOTER_STRIP';
export const MODIFY_CART_ITEM = 'MODIFY_CART_ITEM';
export const OPEN_CHANGE_PASSWORD_MODAL = 'OPEN_CHANGE_PASSWORD_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const OPEN_EDIT_ADDRESS_MODAL = 'OPEN_EDIT_ADDRESS_MODAL';
export const OPEN_EDIT_CONTACT_INFO_MODAL = 'OPEN_EDIT_CONTACT_INFO_MODAL';
export const OPEN_EDIT_PAYMENT_METHOD_MODAL = 'OPEN_EDIT_PAYMENT_METHOD_MODAL';
export const OPEN_EMAIL_MODAL = 'OPEN_EMAIL_MODAL';
export const OPEN_MOBILE_TRAY = 'OPEN_MOBILE_TRAY';
export const OPEN_RECENT_ITEMS_MODAL = 'OPEN_RECENT_ITEMS_MODAL';
export const OPEN_RESET_PASSWORD_MODAL = 'OPEN_RESET_PASSWORD_MODAL';
export const OPEN_SAVED_ITEM_MODAL = 'OPEN_SAVED_ITEM_MODAL';
export const OPEN_LOGIN_FAILED_MODAL = 'OPEN_LOGIN_FAILED_MODAL';
export const PAGE_SET_CANONICAL = 'PAGE_SET_CANONICAL';
export const PAGE_SET_COUPONS = 'PAGE_SET_COUPONS';
export const PAGE_SET_DESCRIPTION = 'PAGE_SET_DESCRIPTION';
export const PAGE_SET_ERROR_CODE = 'PAGE_SET_ERROR_CODE';
export const PAGE_SET_NO_PARTNER = 'PAGE_SET_NO_PARTNER';
export const PAGE_SET_OPEN_GRAPH_TAGS = 'PAGE_SET_OPEN_GRAPH_TAGS';
export const PAGE_SET_SCHEMA = 'PAGE_SET_SCHEMA';
export const PAGE_SET_SERVER_RENDERED = 'PAGE_SET_SERVER_RENDERED';
export const PAGE_SET_TITLE = 'PAGE_SET_TITLE';
export const PAGE_SET_PARTNER_BUSINESS_NAME = 'PAGE_SET_PARTNER_BUSINESS_NAME';
export const PREFILL_ADMIN_REDIRECT = 'PREFILL_ADMIN_REDIRECT';
export const PREFILL_FAQUESTION = 'PREFILL_FAQUESTION';
export const PREFILL_IMPRINT_OPTION = 'PREFILL_IMPRINT_OPTION';
export const PREFILL_QUANTITY = 'PREFILL_QUANTITY';
export const PREFILL_ZIP_CODE = 'PREFILL_ZIP_CODE';
export const REMOVE_CHAT = 'REMOVE_CHAT';
export const SAVE_LAST_CART = 'SAVE_LAST_CART';
export const SET_ACCOUNT_PAGE_TAB = 'SET_ACCOUNT_PAGE_TAB';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_ADMIN_ATTRIBUTES = 'SET_ADMIN_ATTRIBUTES';
export const SET_ADMIN_BULK_EDIT = 'SET_ADMIN_BULK_EDIT';
export const SET_ADMIN_CATEGORIES = 'SET_ADMIN_CATEGORIES';
export const SET_ADMIN_COUPON = 'SET_ADMIN_COUPON';
export const SET_ADMIN_COUPONS = 'SET_ADMIN_COUPONS';
export const SET_ADMIN_EMPLOYEE = 'SET_ADMIN_EMPLOYEE';
export const SET_ADMIN_EMPLOYEES = 'SET_ADMIN_EMPLOYEES';
export const SET_ADMIN_FONTS = 'SET_ADMIN_FONTS';
export const SET_ADMIN_MEGA_MENU = 'SET_ADMIN_MEGA_MENU';
export const SET_ADMIN_OBJECT = 'SET_ADMIN_OBJECT';
export const SET_ADMIN_ORDERS = 'SET_ADMIN_ORDERS';
export const SET_ADMIN_REORDERS = 'SET_ADMIN_REORDERS';
export const SET_ADMIN_PRODUCT_FEATURE = 'SET_ADMIN_PRODUCT_FEATURE';
export const SET_ADMIN_PRODUCT_FEATURES = 'SET_ADMIN_PRODUCT_FEATURES';
export const SET_ADMIN_PRODUCTS = 'SET_ADMIN_PRODUCTS';
export const SET_ADMIN_REDIRECT = 'SET_ADMIN_REDIRECT';
export const SET_ADMIN_REDIRECTS = 'SET_ADMIN_REDIRECTS';
export const SET_ADMIN_SEARCHABLE_COLORS = 'SET_ADMIN_SEARCHABLE_COLORS';
export const SET_ADMIN_SITEWIDE_PRICE_MULTIPLIER = 'SET_ADMIN_SITEWIDE_PRICE_MULTIPLIER';
export const SET_ADMIN_SPECIAL = 'SET_ADMIN_SPECIAL';
export const SET_ADMIN_SPECIALS = 'SET_ADMIN_SPECIALS';
export const SET_ADMIN_SYNONYM_LIST = 'SET_ADMIN_SYNONYM_LIST';
export const SET_ADMIN_THEME_MENU = 'SET_ADMIN_THEME_MENU';
export const SET_ADMIN_VENDOR = 'SET_ADMIN_VENDOR';
export const SET_ADMIN_VENDOR_LIST = 'SET_ADMIN_VENDOR_LIST';
export const SET_ADMIN_PARTNERS = 'SET_ADMIN_PARTNERS';
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const SET_CART = 'SET_CART';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORY_SEARCH_PARAMETERS = 'SET_CATEGORY_SEARCH_PARAMETERS';
export const SET_CATEGORY_SEARCH_RESULTS = 'SET_CATEGORY_SEARCH_RESULTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
export const SET_EMAILS = 'SET_EMAILS';
export const SET_FIRST_MOBILE_TRAY = 'SET_FIRST_MOBILE_TRAY';
export const SET_HOME_PAGE_BANNERS = 'SET_HOME_PAGE_BANNERS';
export const SET_MOBILE_MENU = 'SET_MOBILE_MENU';
export const SET_NAME = 'SET_NAME';
export const SET_ON_LOGIN_ROUTE = 'SET_ON_LOGIN_ROUTE';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_PRINTING_PAGE = 'SET_PRINTING_PAGE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PUBLIC_SERVER_ROOT = 'SET_PUBLIC_SERVER_ROOT';
export const SET_RECENT_ITEMS = 'SET_RECENT_ITEMS';
export const SET_RELATED_PRODUCTS = 'SET_RELATED_PRODUCTS';
export const SET_SAVED_ITEMS = 'SET_SAVED_ITEMS';
export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SERVER_ROOT = 'SET_SERVER_ROOT';
export const SET_SHOPPER_APPROVED_DATA = 'SET_SHOPPER_APPROVED_DATA';
export const SET_PARTNER_CONFIG = 'SET_PARTNER_CONFIG';
export const SET_DOCS = 'SET_DOCS';
export const SET_VENDOR_REPORT = 'SET_VENDOR_REPORT';
export const UPDATE_ADMIN_LOGGED_IN_STATUS = 'UPDATE_ADMIN_LOGGED_IN_STATUS';
export const UPDATE_LOGGED_IN_STATUS = 'UPDATE_LOGGED_IN_STATUS';

export const REPLACE_CATEGORY_EMAILS = 'REPLACE_CATEGORY_EMAILS';
export const REPLACE_CONFIG_EMAILS = 'REPLACE_CONFIG_EMAILS';
export const REPLACE_NAME_CONFIG_EMAILS = 'REPLACE_NAME_CONFIG';
export const REPLACE_CHAT_EMAILS = 'REPLACE_CHAT_EMAILS';
export const REPLACE_PREFILL_IMPRINT_OPTION_EMAILS = 'REPLACE_PREFILL_IMPRINT_OPTION_EMAILS';
export const REPLACE_LAST_CART_EMAILS = 'REPLACE_LAST_CART_EMAILS';
export const REPLACE_SEARCH_EMAILS = 'REPLACE_SEARCH_EMAILS';

export const REPLACE_CATEGORY_PG_LINKS = 'REPLACE_CATEGORY_PG_LINKS';
export const REPLACE_CONFIG_PG_LINKS = 'REPLACE_CONFIG_PG_LINKS';
export const REPLACE_NAME_CONFIG_PG_LINKS = 'REPLACE_NAME_CONFIG_PG_LINKS';
export const REPLACE_CHAT_PG_LINKS = 'REPLACE_CHAT_PG_LINKS';
export const REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS = 'REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS';
export const REPLACE_LAST_CART_PG_LINKS = 'REPLACE_LAST_CART_PG_LINKS';
export const REPLACE_SEARCH_PG_LINKS = 'REPLACE_SEARCH_PG_LINKS';

export const REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA = 'REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA';
export const REPLACE_CATEGORY_BLOG_PG_RELATED_DATA = 'REPLACE_CATEGORY_BLOG_PG_RELATED_DATA';
export const REPLACE_PAGE_PG_RELATED_DATA = 'REPLACE_PAGE_PG_RELATED_DATA';

export const SET_CUSTOMER_REVIEWS = 'SET_CUSTOMER_REVIEWS';
export const SET_API_SA_TOKEN = 'SET_API_SA_TOKEN';

export const SET_COOKIE_SETTINGS = 'SET_COOKIE_SETTINGS';
export const CLOSE_BOTTOM_COOKIE_BANNER = 'CLOSE_BOTTOM_COOKIE_BANNER';
export const CLOSE_DETAILS_COOKIE_PANEL = 'CLOSE_DETAILS_COOKIE_PANEL';
export const OPEN_DETAILS_COOKIE_PANEL = 'OPEN_DETAILS_COOKIE_PANEL';
export const OPEN_BOTTOM_COOKIE_BANNER = 'OPEN_BOTTOM_COOKIE_BANNER';
export const SET_ENABLE_COOKIE_SETTINGS = 'SET_ENABLE_COOKIE_SETTINGS';
export const SET_CONFIG = 'SET_CONFIG';

export const SET_ADMIN_CC_INFORMATION = 'SET_ADMIN_CC_INFORMATION';

export const SET_ADMIN_SSO_CONFIGURATION = 'SET_ADMIN_SSO_CONFIGURATION';

export const SET_ADMIN_CUSTOM_PAYMENT_FLOW = 'SET_ADMIN_CUSTOM_PAYMENT_FLOW';
export const SET_ADMIN_CUSTOM_PAYMENT_FLOW_FIELD = 'SET_ADMIN_CUSTOM_PAYMENT_FLOW_FIELD';

export const SET_WL_TEST_PARTNER_DOMAIN_FOR_LIVE_CHAT = 'SET_WL_TEST_PARTNER_DOMAIN_FOR_LIVE_CHAT';
export const SET_ADMIN_WORK_TIME = 'SET_ADMIN_WORK_TIME';

export const SET_SOCIAL_LINKS = 'SET_SOCIAL_LINKS';

export const SET_FEATURED_CATEGORIES_CAROUSEL = 'SET_FEATURED_CATEGORIES_CAROUSEL';
export const CONFIG_SET_SERVER_ROOT = 'CONFIG_SET_SERVER_ROOT';
export const SET_SHOPPER_APPROVED_SITE_KEY = 'SET_SHOPPER_APPROVED_SITE_KEY';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const SET_HOST = 'SET_HOST';
export const SET_SCRIPTS = 'SET_SCRIPTS';
