import {
    addCartItemRoute,
    addCreditCardRoute,
    addOrderRoute,
    getCartRoute,
    getOrdersRoute,
    getRecentItemsRoute,
    getSavedItemsRoute,
    loginRoute,
    registerRoute,
    reorderRoute,
    taxesRoute
} from '../constants/apiRoutes';
import {getHeadersDomain} from '../lib/headers';

export const getCart = (request, serverRoot) => request.get(`${serverRoot}${getCartRoute}`, {headers: getHeadersDomain()});

export const taxes = (request, serverRoot, addressId, shippingMethod, coupon) =>
    request.post(
        `${serverRoot}${taxesRoute}`,
        {
            address_id: addressId,
            shipping_method: shippingMethod,
            coupon
        },
        {headers: getHeadersDomain()}
    );

export const getSavedItems = (request, serverRoot) => request.get(`${serverRoot}${getSavedItemsRoute}`, {headers: getHeadersDomain()});

export const getOrders = (request, serverRoot) => request.get(`${serverRoot}${getOrdersRoute}`, {headers: getHeadersDomain()});

export const addOrder = (request, serverRoot, data) => request.post(`${serverRoot}${addOrderRoute}`, data, {headers: getHeadersDomain()});

export const reorder = (request, serverRoot, data) => request.post(`${serverRoot}${reorderRoute}`, data, {headers: getHeadersDomain()});

export const register = (request, serverRoot, data) =>
    request.post(`${serverRoot}${registerRoute}`, data, {headers: getHeadersDomain(), withCredentials: true});

export const login = (request, serverRoot, data) =>
    request.post(`${serverRoot}${loginRoute}`, data, {
        headers: getHeadersDomain(),
        withCredentials: true
    });

export const getRecentItems = (request, serverRoot) => request.get(`${serverRoot}${getRecentItemsRoute}`, {headers: getHeadersDomain()});

export const addCreditCard = (request, serverRoot, data) => request.post(`${serverRoot}${addCreditCardRoute}`, data);

export const addCartItem = (request, serverRoot, data) => request.post(`${serverRoot}${addCartItemRoute}`, data);
