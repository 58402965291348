import {logError} from '../log';
import {getPartnerConfig} from '../services/partner';
import {getPartnerFailedMessage} from '../constants/errMessages';
import * as configActions from './configActions';
import {defaultWhiteLabelEmail, defaultThemeColor1, defaultThemeColor2, defaultThemeColor3, defaultWLGrey} from '../constants/common';
import {communicationApproach} from '../constants/partner';
import {getColorLighter, getColorDarker} from '../lib/color';

export function getPartner(serverRoot, axiosInstance) {
    return dispatch =>
        getPartnerConfig(axiosInstance, serverRoot)
            .then(({data}) => {
                const isPartner = Boolean(data.partner_id);

                const theme_color1 = `#${isPartner && data.theme_color1 ? data.theme_color1 : defaultThemeColor1}`;
                const theme_color2 = `#${isPartner && data.theme_color2 ? data.theme_color2 : defaultThemeColor2}`;
                const theme_color3 = `#${isPartner && data.theme_color3 ? data.theme_color3 : defaultThemeColor3}`;
                const theme_color1_fade = getColorDarker(theme_color1, 0.15);
                const theme_color2_fade = getColorDarker(theme_color2, 0.15);
                const theme_color3_fade = getColorDarker(theme_color3, 0.15);
                const theme_color1_tint = getColorLighter(theme_color1, 0.9);
                const theme_color2_tint = getColorLighter(theme_color2, 0.9);
                const theme_color3_tint = getColorLighter(theme_color3, 0.9);
                const theme_color1_tint5 = getColorLighter(theme_color1, 0.95);
                const theme_color2_tint5 = getColorLighter(theme_color2, 0.95);
                const theme_color3_tint5 = getColorLighter(theme_color3, 0.95);
                const email = data.communication_approach === communicationApproach.communicateWithPartner ? data.email : defaultWhiteLabelEmail;
                const showLiveChat = isPartner && data.communication_approach === communicationApproach.communicateWithClient;
                const additionalColors = {
                    wlGrey: ''
                };

                if (isPartner) {
                    additionalColors.wlGrey = defaultWLGrey;
                }

                dispatch(
                    configActions.setPartnerConfig({
                        ...data,
                        isPartner,
                        theme_color1,
                        theme_color2,
                        theme_color3,
                        theme_color1_fade,
                        theme_color2_fade,
                        theme_color3_fade,
                        theme_color1_tint,
                        theme_color2_tint,
                        theme_color3_tint,
                        theme_color1_tint5,
                        theme_color2_tint5,
                        theme_color3_tint5,
                        email,
                        showLiveChat,
                        ...additionalColors
                    })
                );
            })
            .catch(err => {
                if (err.response.status === 404) {
                    return;
                }
                logError(err, getPartnerFailedMessage);
            });
}
