import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';

const defaultState = {
    showBanner: false,
    showDetailsPanel: false,
    performance: true,
    marketing: true,
    analytics: true,
    noCookieSettings: true
};

const SET_COOKIE_SETTINGS = (state, action) => {
    const {cookieSettings} = action;
    if (!cookieSettings) {
        return state;
    }

    const {performance, marketing, analytics, noCookieSettings} = cookieSettings;

    return {
        ...state,
        performance: performance === undefined ? state.performance : performance,
        marketing: marketing === undefined ? state.marketing : marketing,
        analytics: analytics === undefined ? state.analytics : analytics,
        noCookieSettings: noCookieSettings === undefined ? state.noCookieSettings : noCookieSettings,
        showBanner: noCookieSettings === undefined ? state.showBanner : noCookieSettings
    };
};

const OPEN_DETAILS_COOKIE_PANEL = state => ({...state, showBanner: false, showDetailsPanel: true});

const OPEN_BOTTOM_COOKIE_BANNER = state => ({...state, showBanner: true, showDetailsPanel: false});

const CLOSE_BOTTOM_COOKIE_BANNER = state => ({...state, showBanner: false, showDetailsPanel: false});

const CLOSE_DETAILS_COOKIE_PANEL = state => ({...state, showBanner: false, showDetailsPanel: false});

const handlers = {};
handlers[actionTypes.SET_COOKIE_SETTINGS] = SET_COOKIE_SETTINGS;
handlers[actionTypes.CLOSE_BOTTOM_COOKIE_BANNER] = CLOSE_BOTTOM_COOKIE_BANNER;
handlers[actionTypes.CLOSE_DETAILS_COOKIE_PANEL] = CLOSE_DETAILS_COOKIE_PANEL;
handlers[actionTypes.OPEN_DETAILS_COOKIE_PANEL] = OPEN_DETAILS_COOKIE_PANEL;
handlers[actionTypes.OPEN_BOTTOM_COOKIE_BANNER] = OPEN_BOTTOM_COOKIE_BANNER;
export default createReducer(defaultState, handlers);
