import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';

const defaultState = {
    reviews: []
};

const SET_CUSTOMER_REVIEWS = (state, action) => {
    const {reviews} = action;
    if (!reviews) {
        return state;
    }

    return {...state, reviews};
};

const handlers = {};
handlers[actionTypes.SET_CUSTOMER_REVIEWS] = SET_CUSTOMER_REVIEWS;
export default createReducer(defaultState, handlers);
