import {urlQueryDelimiter} from '../constants/common';
import {getAbsoluteUrl} from './url';

export function getProductSkuPrefix(skuPrefix, {sku_prefix: partnerSkuPrefix}) {
    return partnerSkuPrefix ? `${partnerSkuPrefix}${skuPrefix}` : skuPrefix;
}

export function getProductUrl(url, productId, domain = null) {
    return getAbsoluteUrl(domain, `${urlQueryDelimiter}${[url, productId].join(urlQueryDelimiter)}`);
}
