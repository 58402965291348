/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';
import {replacePrintglobeRelatedData} from '../lib/pdExcludes';

const defaultState = {
    product: {},
    zip_code: null,
    quantity: null,
    option_index: null,
    relatedProducts: []
};

function SET_PRODUCT(state, action) {
    const {product} = action;
    if (!product) {
        return state;
    }
    return {...state, product};
}

function SET_RELATED_PRODUCTS(state, action) {
    const {relatedProducts} = action;
    return {...state, relatedProducts};
}

function PREFILL_ZIP_CODE(state, action) {
    const {zip_code, isResidential} = action;
    return {...state, zip_code, isResidential};
}

function PREFILL_QUANTITY(state, action) {
    const {quantity} = action;
    return {...state, quantity};
}

function PREFILL_IMPRINT_OPTION(state, action) {
    const {option_index} = action;
    return {...state, option_index};
}

function REPLACE_PREFILL_IMPRINT_OPTION_EMAILS(state) {
    return replaceEmails(state);
}

function REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS(state) {
    return convertHyperlinkToText(state);
}

function REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA(state, action) {
    return replacePrintglobeRelatedData(state, action.wlConfig);
}

const handlers = {};
handlers[actionTypes.SET_PRODUCT] = SET_PRODUCT;
handlers[actionTypes.SET_RELATED_PRODUCTS] = SET_RELATED_PRODUCTS;
handlers[actionTypes.PREFILL_ZIP_CODE] = PREFILL_ZIP_CODE;
handlers[actionTypes.PREFILL_QUANTITY] = PREFILL_QUANTITY;
handlers[actionTypes.PREFILL_IMPRINT_OPTION] = PREFILL_IMPRINT_OPTION;
handlers[actionTypes.REPLACE_PREFILL_IMPRINT_OPTION_EMAILS] = REPLACE_PREFILL_IMPRINT_OPTION_EMAILS;
handlers[actionTypes.REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS] = REPLACE_PREFILL_IMPRINT_OPTION_PG_LINKS;
handlers[actionTypes.REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA] = REPLACE_PREFILL_IMPRINT_OPTION_PG_RELATED_DATA;
export default createReducer(defaultState, handlers);
