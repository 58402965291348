import {isEmpty} from 'lodash';
import {statusActive} from '../constants/product';
const categoriesIndexesInFirsParents = [1, 2, 3];

export function getProductUrlOrRedirect(product) {
    if (!product) {
        return '/';
    }

    return product.redirect ? product.redirect.destination_url : `/${product.url_name}/${product.id}`;
}

export function getProductCustomizeUrlOrRedirect(product) {
    if (!product) {
        return '/';
    }

    return product.redirect ? product.redirect.destination_url : `/customize/${product.url_name}/${product.id}`;
}

export function getPriceWithDiscount(price, discount = 0) {
    if (!discount) {
        return price;
    }

    return Number(price * (1 - discount / 100.0)).toFixed(2);
}

export function getCategories(product) {
    return product.parents && product.parents.length ? product.parents[0].map(p => p.name) : [];
}

export function getFullProductUrl(urlRoot, product) {
    return `${urlRoot}/${product.url_name}/${product.id}`;
}

function getFirstParentItemNameOrDefault(firstParents, index) {
    return firstParents[index] ? firstParents[index].name : '';
}

export function getGAContentGroup(product) {
    const [firstParents] = product.parents;
    const isFirstParentsExist = !isEmpty(firstParents);

    const contentGroups = categoriesIndexesInFirsParents.map(index => (isFirstParentsExist ? getFirstParentItemNameOrDefault(firstParents, index) : ''));

    return ['product', ...contentGroups, product.name];
}

export function getGACategories(parents) {
    return parents.length
        ? parents[0].reduce((categoriesObj, category, categoryIndex) => {
              categoriesObj[`item_category${categoryIndex > 0 ? ++categoryIndex : ''}`] = category.name;

              return categoriesObj;
          }, {})
        : {};
}

export function getFilteredAddons(addons) {
    return addons.filter(addon => addon.optional).filter(addon => addon.prices.reduce((val, next) => Number(next) > 0 || val, false));
}

export function getSetupOnlyAddons(addons) {
    return addons.filter(addon => Number(addon.setupFee) > 0);
}

export function checkStatusIsActive(status) {
    return status === statusActive;
}
