import * as actionTypes from '../actionTypes';
import createReducer from 'lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';
import {partnerStatuses} from '../constants/partner';

const defaultState = {
    serverRoot: '',
    publicServerRoot: '',
    ttSiteKey: '',
    wlConfig: {
        partner_id: '',
        status: partnerStatuses.inactive,
        update_date: '',
        commissionMinimum: 0,
        time_zone: '',
        day_start: '',
        day_end: '',
        time_start: '',
        time_end: '',
        enable_shipping_multiplier: false,
        shipping_multiplier: 0,
        commission_minimum: 0,
        default_shipping_address: {
            id: 0,
            wl_partner_id: 0,
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            zip: ''
        }
    },
    enableCookieSettings: false,
    gaOptimizeContainerId: '',
    preventCrawlers: false,
    buildVersion: '',
    wlKlaviyoPublicKey: '',
    klaviyoPublicKey: '',
    useKlaviyo: false,
    wlGoogleTagManagerId: '',
    pgGoogleTagManagerId: '',
    wlGa4MeasurementId: '',
    ga4MeasurementId: '',
    wlGaMeasurementId: '',
    gaMeasurementId: '',
    disableGA: false,
    authCookieName: '',
    cookieSettingsCookieName: '',
    adminAuthCookieName: '',
    saSite: '',
    saToken: '',
    apiSaToken: '',
    rootUri: '',
    pgfbpixelid: '',
    wlLiveChatId: '',
    pgPjPubKey: '',
    pgPjPubUrl: '',
    pgAudioeyeSiteHash: ''
};

export default createReducer(defaultState, {
    [actionTypes.CONFIG_SET_SERVER_ROOT](state, action) {
        return {...state, serverRoot: action.serverRoot};
    },
    [actionTypes.SET_PUBLIC_SERVER_ROOT](state, action) {
        return {...state, publicServerRoot: action.publicServerRoot};
    },
    [actionTypes.SET_PARTNER_CONFIG](state, action) {
        return {...state, wlConfig: action.wlConfig};
    },
    [actionTypes.SET_SHOPPER_APPROVED_SITE_KEY](state, action) {
        return {...state, saSite: action.saSite};
    },
    [actionTypes.SET_SHOPPER_APPROVED_DATA](state, action) {
        return {...state, saToken: action.saToken, saSite: action.saSite};
    },
    [actionTypes.REPLACE_CONFIG_EMAILS](state) {
        return replaceEmails(state);
    },
    [actionTypes.REPLACE_CONFIG_PG_LINKS](state) {
        return convertHyperlinkToText(state);
    },
    [actionTypes.SET_API_SA_TOKEN](state, action) {
        return {...state, apiSaToken: action.token};
    },
    [actionTypes.SET_ENABLE_COOKIE_SETTINGS](state, action) {
        return {...state, enableCookieSettings: action.enableCookieSettings};
    },
    [actionTypes.SET_CONFIG](state, action) {
        return {...state, ...action.state};
    }
});
