/* @flow */
import * as actionTypes from '../actionTypes';
import createReducer from '../lib/createReducer';
import {replaceEmails} from '../lib/email';
import {convertHyperlinkToText} from '../lib/hyperlinks';
import {replacePrintglobeRelatedData} from '../lib/pdExcludes';

const defaultState = {
    aggregations: {},
    category: {},
    params: {},
    products: [],
    total: 0
};

function SET_CATEGORY(state, action) {
    const {category, products, total, aggregations, params} = action;
    if (!category) {
        return state;
    }
    return {...state, category, products, total, aggregations, params};
}

function SET_CATEGORY_SEARCH_RESULTS(state, action) {
    let {products, total, aggregations, params} = action;
    return {...state, products, total, aggregations, params};
}

function SET_CATEGORY_SEARCH_PARAMETERS(state, action) {
    const {params} = action;
    if (!params) {
        return state;
    }
    return {...state, params};
}

function REPLACE_CATEGORY_EMAILS(state) {
    return replaceEmails(state);
}

function REPLACE_CATEGORY_PG_LINKS(state) {
    return convertHyperlinkToText(state);
}

function REPLACE_CATEGORY_BLOG_PG_RELATED_DATA(state, action) {
    return replacePrintglobeRelatedData(state, action.wlConfig);
}

// function CLEAR_SEARCH(): CategoryStateType {
// 	return defaultState;
// }

const handlers = {};
handlers[actionTypes.SET_CATEGORY] = SET_CATEGORY;
handlers[actionTypes.SET_CATEGORY_SEARCH_RESULTS] = SET_CATEGORY_SEARCH_RESULTS;
handlers[actionTypes.SET_CATEGORY_SEARCH_PARAMETERS] = SET_CATEGORY_SEARCH_PARAMETERS;
handlers[actionTypes.REPLACE_CATEGORY_EMAILS] = REPLACE_CATEGORY_EMAILS;
handlers[actionTypes.REPLACE_CATEGORY_PG_LINKS] = REPLACE_CATEGORY_PG_LINKS;
handlers[actionTypes.REPLACE_CATEGORY_BLOG_PG_RELATED_DATA] = REPLACE_CATEGORY_BLOG_PG_RELATED_DATA;
export default createReducer(defaultState, handlers);
